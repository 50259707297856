import { Comment } from '@app-shared/models';
import comments from './data/comments-vacancy.json';

export const mockedVacanciesList = [
  {
    name: 'Angular developer in Rivne',
    numbersOfTalent: 1,
    id: 96563,
    createdAt: '2021-01-25T13:29:00+00:00',
  },
  {
    name: 'Node.js developer in Rivne',
    numbersOfTalent: 1,
    id: 96562,
    createdAt: '2021-01-25T11:49:59+00:00',
  },
  {
    name: 'Middle Python QA Manager 1',
    numbersOfTalent: 3,
    id: 96508,
    clients: [{ firstName: 'Larisa', lastName: 'Kasapa' }],
    createdAt: '2021-01-04T14:12:15+00:00',
  },
  { name: 'test', numbersOfTalent: 1, id: 96507, createdAt: '2021-01-04T14:11:52+00:00' },
  { name: 'test', numbersOfTalent: 1, id: 96506, createdAt: '2020-12-28T12:01:09+00:00' },
  { name: 'test1226', numbersOfTalent: 1, id: 96499, createdAt: '2020-12-26T14:44:23+00:00' },
  {
    name: 'Vacancy8993445120',
    numbersOfTalent: 1,
    id: 96498,
    createdAt: '2020-12-26T14:37:40+00:00',
  },
  {
    name: 'Vacancy8993264195',
    numbersOfTalent: 1,
    id: 96497,
    createdAt: '2020-12-26T14:34:39+00:00',
  },
  {
    name: 'Vacancy8992878170',
    numbersOfTalent: 1,
    id: 96496,
    createdAt: '2020-12-26T14:28:14+00:00',
  },
  {
    name: 'Vacancy8992708404',
    numbersOfTalent: 1,
    id: 96495,
    createdAt: '2020-12-26T14:25:23+00:00',
  },
  {
    name: 'Vacancy8991749566',
    numbersOfTalent: 1,
    id: 96494,
    createdAt: '2020-12-26T14:09:25+00:00',
  },
  {
    name: 'Vacancy8990894375',
    numbersOfTalent: 1,
    id: 96493,
    createdAt: '2020-12-26T13:55:09+00:00',
  },
  {
    name: 'Vacancy8990357964',
    numbersOfTalent: 1,
    id: 96492,
    createdAt: '2020-12-26T13:46:13+00:00',
  },
  {
    name: 'Vacancy8926065259',
    numbersOfTalent: 1,
    id: 96479,
    createdAt: '2020-12-25T19:54:41+00:00',
  },
  {
    name: 'Vacancy8925872004',
    numbersOfTalent: 1,
    id: 96478,
    createdAt: '2020-12-25T19:51:27+00:00',
  },
  {
    name: 'Vacancy8925718007',
    numbersOfTalent: 1,
    id: 96477,
    createdAt: '2020-12-25T19:48:53+00:00',
  },
  {
    name: 'Vacancy8925311032',
    numbersOfTalent: 1,
    id: 96476,
    createdAt: '2020-12-25T19:42:06+00:00',
  },
  {
    name: 'Vacancy8924394361',
    numbersOfTalent: 1,
    id: 96475,
    createdAt: '2020-12-25T19:26:49+00:00',
  },
  {
    name: 'Vacancy8923972142',
    numbersOfTalent: 1,
    id: 96474,
    createdAt: '2020-12-25T19:19:47+00:00',
  },
  {
    name: 'Vacancy8923448870',
    numbersOfTalent: 1,
    id: 96473,
    createdAt: '2020-12-25T19:11:04+00:00',
  },
  {
    name: 'Vacancy8922990821',
    numbersOfTalent: 1,
    id: 96472,
    createdAt: '2020-12-25T19:03:25+00:00',
  },
  {
    name: 'Vacancy8922706461',
    numbersOfTalent: 1,
    id: 96471,
    createdAt: '2020-12-25T18:58:41+00:00',
  },
  {
    name: 'Vacancy8922241035',
    numbersOfTalent: 1,
    id: 96470,
    createdAt: '2020-12-25T18:50:56+00:00',
  },
  {
    name: 'Vacancy8922032525',
    numbersOfTalent: 1,
    id: 96469,
    createdAt: '2020-12-25T18:47:27+00:00',
  },
  {
    name: 'Vacancy8921349494',
    numbersOfTalent: 1,
    id: 96468,
    createdAt: '2020-12-25T18:36:04+00:00',
  },
  {
    name: 'Vacancy8921089583',
    numbersOfTalent: 1,
    id: 96467,
    createdAt: '2020-12-25T18:31:44+00:00',
  },
  {
    name: 'Vacancy8920899834',
    numbersOfTalent: 1,
    id: 96466,
    createdAt: '2020-12-25T18:28:34+00:00',
  },
  {
    name: 'Vacancy8920319088',
    numbersOfTalent: 1,
    id: 96465,
    createdAt: '2020-12-25T18:18:53+00:00',
  },
  {
    name: 'Vacancy8854715466',
    numbersOfTalent: 1,
    id: 96452,
    createdAt: '2020-12-25T00:05:29+00:00',
  },
  {
    name: 'Vacancy8837731414',
    numbersOfTalent: 1,
    id: 96442,
    createdAt: '2020-12-24T19:22:24+00:00',
  },
  {
    name: 'Vacancy8823031959',
    numbersOfTalent: 1,
    id: 96439,
    createdAt: '2020-12-24T15:17:27+00:00',
  },
  {
    name: 'Vacancy8822440818',
    numbersOfTalent: 1,
    id: 96437,
    createdAt: '2020-12-24T15:07:34+00:00',
  },
  { name: 'test', numbersOfTalent: 1, id: 96436, createdAt: '2020-12-24T14:59:19+00:00' },
  { name: 'test', numbersOfTalent: 1, id: 96435, createdAt: '2020-12-24T14:26:52+00:00' },
  {
    name: 'Vacancy8814953751',
    numbersOfTalent: 1,
    id: 96434,
    createdAt: '2020-12-24T13:02:47+00:00',
  },
  {
    name: 'Vacancy8812618328',
    numbersOfTalent: 1,
    id: 96433,
    createdAt: '2020-12-24T12:25:18+00:00',
  },
  {
    name: 'Node.js developer in Lutsk',
    numbersOfTalent: 1,
    id: 96417,
    createdAt: '2020-12-17T09:39:47+00:00',
  },
  {
    name: 'Middle Python QA Manager false',
    numbersOfTalent: 3,
    id: 96412,
    clients: [{ firstName: 'Larisa', lastName: 'Kasapa' }],
    createdAt: '2020-12-09T20:58:56+00:00',
  },
  { name: 'test', numbersOfTalent: 1, id: 96404, createdAt: '2020-11-30T13:12:27+00:00' },
  { name: '321222', numbersOfTalent: 1, id: 96400, createdAt: '2020-08-08T17:03:28+00:00' },
  { name: 'New vacancy', numbersOfTalent: 1, id: 96395, createdAt: '2020-07-25T19:44:32+00:00' },
  {
    name: 'Junior frontend angular developer from Kyiv',
    numbersOfTalent: 1,
    id: 96370,
    createdAt: '2020-05-23T12:06:14+00:00',
  },
  { name: 'driver', numbersOfTalent: 1, id: 96360, createdAt: '2020-04-28T08:55:42+00:00' },
  {
    name: '\u0412\u043e\u0434\u0438\u0442\u0435\u043b\u044c \u0432 \u041a\u0438\u0435\u0432',
    numbersOfTalent: 1,
    id: 96359,
    createdAt: '2020-04-28T08:03:52+00:00',
  },
  { name: 'Some name', numbersOfTalent: 1, id: 96357, createdAt: '2020-04-28T06:07:34+00:00' },
  {
    name: 'Frontend developer',
    numbersOfTalent: 1,
    id: 96349,
    clients: [{ firstName: 'Illya', lastName: 'Kozachenko' }],
    createdAt: '2020-03-26T13:53:22+00:00',
  },
  { name: 'test', numbersOfTalent: 1, id: 96348, createdAt: '2020-03-26T13:04:52+00:00' },
  {
    name: 'Middle Front-end Developer',
    numbersOfTalent: 1,
    id: 96340,
    clients: [{ firstName: 'Nino', lastName: 'Lo Schiavo' }],
    createdAt: '2019-12-13T11:09:53+00:00',
  },
  {
    name: 'Middle Python QA Manager 1',
    numbersOfTalent: 3,
    id: 45000,
    clients: [{ firstName: 'Larisa', lastName: 'Kasapa' }],
    createdAt: '2020-03-08T12:10:06+00:00',
  },
];
export const mockedVacancyComments: Comment[] = comments;
