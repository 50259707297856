/* eslint-disable no-shadow */
import { Comment, DictionaryItem, DirectoryCustomFieldResponse, Entity, Recruiter } from '.';

export type ListType = 'long-list' | 'short-list';

export const VacancyBidStatus = {
  long_list: {
    name: 'Long List',
    sort: 0,
  },
  ai: {
    name: 'AI',
    sort: 1,
  },
  unresolved: {
    name: 'Applies',
    sort: 2,
  },
  request_avail: {
    name: 'Analyzing profile',
    sort: 3,
  },
  send_cv: {
    name: 'Sent to Reviewer',
    sort: 4,
  },
  arrange_interview: {
    name: 'Interview planed',
    sort: 5,
  },
  confirm: {
    name: 'Hired',
    sort: 6,
  },
  not_appropriate: {
    name: 'Not appropriate',
    sort: 7,
  },
};
export type VacancyBidStatuses = keyof typeof VacancyBidStatus;

export interface VacanciesList {
  items: Vacancy;
  totalCount: number;
}
export interface IncludedItem extends Entity {
  name: string;
  count: number;
  custom?: boolean;
  code?: string;
}
export interface ProfilesIncluded {
  authors: IncludedItem[];
  rejectReasons: IncludedItem[];
  sources: IncludedItem[];
  statuses: IncludedItem[];
}
export interface VacancyCounts {
  ai: number;
  hired: number;
  inbox: number;
  longList: number;
  notAccepted: number;
  notified: number;
  processing: number;
}
export interface Vacancy extends Entity, VacancyCounts {
  assignedId: Recruiter;
  countComments: number;
  city: Entity | null;
  country: Entity | null;
  clients:
    | (Entity & {
        color?: string;
        firstName?: string;
        lastName?: string;
        teamName?: string;
      })[]
    | null;
  createdAt: string;
  currentStatus: number;
  deadlineDate: string | null;
  fieldValues: DirectoryCustomFieldResponse[];
  firstCvSentAt: string | null;
  firstHireAt: string | null;
  firstInterviewAt: string | null;
  hotVacancy: boolean;
  inside_information: string | null;
  lastComment: Pick<Comment, 'id' | 'comment'> | null;
  name: string;
  numbersOfTalent: number;
  rate: number | null;
  recruiterFavorites: Recruiter[];
  showInPublicWebsite: boolean;
  slug: string;
  speciality: Entity | null;
  successClosedDate?: string | null;
  types: number[];
  unsuccessClosedDate?: string | null;
  workingDays: number;
}

export interface VacancyDetails extends Omit<Vacancy, 'countComments' | 'lastComment'> {
  author: Recruiter;
  board: Entity;
  comments: Comment[];
  currency: string | null;
  daysToDeadline: number;
  department: string | null;
  description: string | null;
  duration: number | null;
  employment: number | null;
  keywords: string[];
  level: number | null;
  salary: string | null;
  salaryComment: string | null;
  skills: Array<Entity & { main: boolean; skillId: number; skill: DictionaryItem }>;
  startDate: string | null;
}

export interface VacancyCreatePayload
  extends Omit<
    VacancyDetails,
    | 'author'
    | 'comments'
    | 'daysToDeadline'
    | 'employment'
    | 'level'
    | 'id'
    | 'keywords'
    | 'skills'
    | 'slug'
    | 'workingDays'
    | keyof VacancyCounts
  > {
  skills: Array<{ id?: number; main: boolean; skillId: number; skill?: DictionaryItem }>;
}
export interface VacancyEditPayload extends VacancyCreatePayload {
  id: number;
}

export type VacancySimpleAssignedRecruiterUpdatePayload = Entity & {
  assignedId: number;
};
export type VacancySimpleStatusUpdatePayload = Entity & {
  status: number;
};
export type VacancySimpleVisibilityUpdatePayload = Entity & {
  isVisible: boolean;
};

export type VacancySimpleUpdatePayload =
  | VacancySimpleAssignedRecruiterUpdatePayload
  | VacancySimpleStatusUpdatePayload
  | VacancySimpleVisibilityUpdatePayload;

export type VacancySimpleUpdateType = 'assigned' | 'status' | 'visibility';

export interface VacancyActivities {
  authors: Record<string, number>;
  candidatesCount: number;
  rejections: { [key: string]: number };
  sources: Record<string, { [key: string]: number }>;
  statuses: Record<string, { [key: string]: number }>;
}

export type VacancyTabs =
  | 'kanban'
  | 'notified'
  | 'long_list'
  | 'applies'
  | 'ai'
  | 'processing'
  | 'hired'
  | 'not_accepted';
export interface VacancyComment extends Entity {
  addedBy: Entity & {
    firstName: string;
    lastName: string;
    shortName?: string;
    lastLogin?: string;
    imageThumbnail?: string;
    email?: string;
    skype?: string;
    image?: string;
    created_at?: string;
  };
  comment: string;
  createdAt: string;
}

export interface VacancyDictionary
  extends Pick<Vacancy, 'createdAt' | 'id' | 'name' | 'numbersOfTalent'> {
  client?: {
    firstName: string;
    lastName: string;
  };
}

export enum vacancyDefaultBoardNames {
  'AI' = 'add-new-entity.new-vacancy.board-names.ai',
  'Applies' = 'add-new-entity.new-vacancy.board-names.applies',
  'Interview Planned' = 'add-new-entity.new-vacancy.board-names.interview-planned',
  'Hired' = 'add-new-entity.new-vacancy.board-names.hired',
  'Long List' = 'add-new-entity.new-vacancy.board-names.long-list',
  'Not accepted' = 'add-new-entity.new-vacancy.board-names.not-accepted',
  'Sent to Reviewer' = 'add-new-entity.new-vacancy.board-names.sent-to-review',
}

export enum vacancyStatuses {
  'Not Published' = 'shared.models.vacancy.statuses.not-published',
  'Hot' = 'shared.models.vacancy.statuses.hot',
  'Lost' = 'shared.models.vacancy.statuses.lost',
  'Deal done' = 'shared.models.vacancy.statuses.deal-done',
  'On Hold' = 'shared.models.vacancy.statuses.on-hold',
  'Sent to Reviewer' = 'shared.models.vacancy.statuses.sent',
  'Interview planned' = 'shared.models.vacancy.statuses.interview',
  'Waiting for a feedback' = 'shared.models.vacancy.statuses.waiting',
  'Passive Search' = 'shared.models.vacancy.statuses.passive',
}

export enum vacancyWorkTypes {
  'Remote' = 'shared.models.vacancy-types.remote',
  'Full Time Office' = 'shared.models.vacancy-types.full-time-office',
  'Paid Relocation' = 'shared.models.vacancy-types.paid-relocation',
}

export interface VacancySuggestedParams {
  city: number[] | null;
  duration: number | null;
  level: number | null;
  mainSkill: number[] | null;
  skills: number[] | null;
  speciality: number[] | null;
  type: number | null;
}

export const enum VacanciesListQuickFilters {
  ANY,
  MY_ACTIVE,
  MY,
  ALL_ACTIVE,
  NEW,
}

export interface VacancyPublicSuggestions {
  publicDescriptionOffer: string;
  publicDescriptionRequirements: string;
  publicDescriptionExperience: string;
}
