import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatSelect } from '@angular/material/select';
import {
  DictionaryCandidateStage,
  DirectoryBidStatusForm,
  DirectoryFieldType,
} from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { has, identity, ifElse, map, pick, pipe, propOr } from 'ramda';
import { DirectoryCustomFieldsComponent } from '../directory-custom-fields/directory-custom-fields.component';
import { DirectoryEntityFormComponent } from '../directory-generic-form';

@Component({
  imports: [
    ColorPickerModule,
    DirectoryCustomFieldsComponent,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatRadioButton,
    MatRadioGroup,
    MatSelect,
    NgStyle,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'directories-bid-status-form',
  templateUrl: './directory-bid-status-form.component.html',
  styleUrl: './directory-bid-status-form.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class DirectoryBidStatusFormComponent extends DirectoryEntityFormComponent {
  @Input() public statusesCustomFields: DirectoryFieldType[] = [];
  @Output() public showWarningMessage = new EventEmitter<string>();
  public candidateStagesColor = ['#5e89f2', '#fcbd2e', '#3bc46a', '#e42f4d', '#ffffff'];
  public currentTypeField = [
    { name: 'text', title: 'add-new-entity.new-directory.field-type.text' },
    { name: 'date', title: 'add-new-entity.new-directory.field-type.date' },
    { name: 'int', title: 'add-new-entity.new-directory.field-type.int' },
    { name: 'bool', title: 'add-new-entity.new-directory.field-type.bool' },
    { name: 'array', title: 'add-new-entity.new-directory.field-type.array' },
    { name: 'longtext', title: 'add-new-entity.new-directory.field-type.longtext' },
  ];
  public entityForm?: FormGroup<DirectoryBidStatusForm>;

  constructor(private readonly formBuilder: FormBuilder) {
    super();
  }

  public createForm(): FormGroup<DirectoryBidStatusForm> {
    return this.formBuilder.group<DirectoryBidStatusForm>({
      name: new FormControl<string>(null, [Validators.required]),
      color: new FormControl<string>(null, [Validators.required]),
      fieldTypes: new FormControl<DirectoryFieldType[]>([]),
    });
  }
  public get formDataForSymfony(): Omit<
    DictionaryCandidateStage,
    'id' | 'code' | 'position' | 'shortlistCount'
  > {
    const formValue = this.entityForm.value;

    return {
      name: formValue.name,
      color: formValue.color,
      attachedFieldTypes: pipe(
        propOr([], 'fieldTypes'),
        map(ifElse(has('id'), pick(['id']), identity)),
      )(formValue) as DirectoryFieldType[],
    };
  }
  public get selectedColor(): string {
    return this.entityForm.get('color').value;
  }
  public updateFormWithData(item: DictionaryCandidateStage) {
    this.entityForm.setValue({
      name: item.name,
      color: item.color,
      fieldTypes: item.attachedFieldTypes,
    });
  }
}
