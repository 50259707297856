import {
  CustomForm,
  Vacancy,
  VacancyActivities,
  VacancyDetails,
  VacancyPublicSuggestions,
} from '../models';

import kernelCustomForm from './data/new-vacancy-custom-form-kernel.json';
import customForm from './data/new-vacancy-custom-form.json';
import list from './vacancies/vacancies-list.json';
import activities from './vacancies/vacancy-activities.json';
import details from './vacancies/vacancy-details.json';

export const mockedVacancies: Vacancy[] = list;

export const mockedVacancyDetails: VacancyDetails = details;

export const mockedVacancyActivities: VacancyActivities =
  activities as unknown as VacancyActivities;

export const mockedVacancyCustomForm = customForm as CustomForm;
export const mockedVacancyKernelCustomForm = kernelCustomForm as CustomForm;

export const mockedVacancyPublicDescriptions: VacancyPublicSuggestions = {
  publicDescriptionOffer: 'We offer a great opportunity to join our team',
  publicDescriptionRequirements: 'You will develop new features and maintain existing ones',
  publicDescriptionExperience: 'We expect you to have at least 3 years of experience',
};
