import {
  CurrentUser,
  DefaultNotificationRule,
  Metadata,
  NotificationRuleCode,
  NotificationRuleFilter,
  RecruiterInfo,
  RecruiterNotificationRule,
  RecruiterPayload,
  SpaceEntity,
  SpaceSettings,
  TutorialProgress,
  UserOptions,
} from '@app-shared/models';
import { omit, pipe } from 'ramda';
import spaces from './dictionaries/portals.json';
import teammates from './dictionaries/teammates.json';
import user from './profiles/alexandr-stetsuk-296.json';
import recruiter from './profiles/recruiter-296.json';

export const mockedUser: CurrentUser = user as CurrentUser;

export const mockedMetadata: Metadata = {
  availableFilters: {
    candidates: [
      'excludeProcessedCandidates',
      'excludeWatchedCandidates',
      'vacancy',
      'skills',
      'keywords',
      'country',
      'city',
      'tags',
      'notUnsubscribed',
      'hasEmail',
      'hasSkype',
      'hasPhone',
      'hasCV',
      'skillExceptions',
      'tagExceptions',
      'keywordExceptions',
      'hadContract',
      'complicatedRequest',
      'showToClient',
      'confirmed',
      'experience',
      'lastPositionExperience',
      'addedBy',
      'countriesCount',
      'citiesCount',
    ],
    profiles: [
      'vacancy',
      'skills',
      'keywords',
      'country',
      'city',
      'tags',
      'hasEmail',
      'hasSkype',
      'hasPhone',
      'skillExceptions',
      'tagExceptions',
      'keywordExceptions',
      'complicatedRequest',
      'experience',
      'lastPositionExperience',
      'countriesCount',
      'citiesCount',
    ],
    vacancy: [
      'vacancy',
      'hasEmail',
      'hasSkype',
      'hasPhone',
      'hasCV',
      'notUnsubscribed',
      'addedByRecruiters',
    ],
    vacanciesList: [
      'keywords',
      'datePeriod',
      'vacancyStatus',
      'vacancyType',
      'recruiters',
      'typeOfDatePeriod',
      'clients',
      'country',
      'city',
    ],
  },
  availableSortings: {
    candidates: ['skill', 'createdAt', 'country', 'city', 'lastName', 'updatedAt'],
    profiles: ['skill', 'createdAt', 'country', 'city', 'lastName', 'updatedAt'],
    vacancy: ['createdAt', 'lastName', 'updatedAt'],
    vacanciesList: ['createdAt', 'status', 'name', 'recruiters'],
  },
};

export const mockedRecruiter: RecruiterInfo = recruiter;
export const mockedRecruiterPayload: RecruiterPayload = pipe(
  omit(['firstLogin', 'lastLogin', 'recruiterDepartmentAccess', 'subordinates']),
  (r) => ({
    ...r,
    recruiterDepartment: ['1'],
    subordinates: [56, 334],
  }),
)(recruiter);

export const mockedAllNotificationRules: DefaultNotificationRule[] = [
  {
    id: 1,
    name: 'Create vacancy',
    code: NotificationRuleCode.CREATE_VACANCY,
    sendgridTemplateId: 'd-67f07a0c21ee4cc8b196298e8d3c60bd',
    availableFilters: [NotificationRuleFilter.VACANCY],
  },
  {
    id: 2,
    name: 'Change vacancy assignment',
    code: NotificationRuleCode.CHANGE_VACANCY_ASSIGNMENT,
    sendgridTemplateId: 'd-9e98a2e2a20643d6b71956529e81cb0e',
    availableFilters: [NotificationRuleFilter.RECRUITER],
  },
  {
    id: 3,
    name: 'Change vacancy team',
    code: NotificationRuleCode.CHANGE_VACANCY_TEAM,
    sendgridTemplateId: 'd-58ce853f9be742ab9244df08685ca548',
    availableFilters: [NotificationRuleFilter.RECRUITER],
  },
];
export const mockedRecruiterNotificationRules: RecruiterNotificationRule[] = [
  {
    id: 2,
    recruiterId: 296,
    portalId: 2,
    ruleId: 1,
    channel: 'email',
    filterBy: NotificationRuleFilter.RECRUITER,
    filterValue: 296,
  },
  {
    id: 4,
    recruiterId: 296,
    portalId: 2,
    ruleId: 2,
    channel: 'push',
    filterBy: NotificationRuleFilter.VACANCY,
    filterValue: 101565,
  },
  {
    id: 8,
    recruiterId: 296,
    portalId: 2,
    ruleId: 3,
    channel: 'email',
    filterBy: NotificationRuleFilter.VACANCY,
    filterValue: 101565,
  },
];

export const mockedSpaceSettings: SpaceSettings = {
  allow_apply_from_public_website: true,
  days_to_deadline: 21,
  download_profiles_from_linkedin: true,
  download_visited_profiles_to_portal: true,
  gdpr_should_sent_notification: true,
  get_first_connection_contact: false,
  id: 2,
  log_events_between_vacancy_and_profile: true,
  mail_url: 'https://stage-jobs.talentscan.pro',
  name: 'AOG Outstaffing',
  search_duplicates_in_a_t_s: true,
  search_duplicates_in_console: true,
  search_duplicates_in_details: true,
  update_master_profile: true,
  update_other_portal_profile: true,
};

export const mockedSpaces: SpaceEntity[] = spaces;

export const mockedTeammates: RecruiterInfo[] = teammates;

export const mockedAvailableOptions: UserOptions = {
  clientsPage: true,
  dashboardPage: true,
  deleteComments: true,
  deleteVacancy: true,
  downloadVisitedProfilesToPortal: false,
  editVacancyCloseDate: true,
  editVacancyHotStatus: true,
  editVacancyInformation: true,
  searchBySecurityId: true,
  searchConsolePage: true,
  showCandidateStatusCustomFields: true,
  showClientsContactsDetails: true,
  showClientsCustomFields: true,
  showProfileContactDetails: true,
  showProfileCustomFields: true,
  showProfileSecondName: true,
  showProfileSocialLinks: true,
  showSalary: true,
  showSocialLinks: true,
  showVacancyCustomFields: true,
  teammatesPage: true,
  vacancyNotificationsTab: true,
  vacancyStaticTab: true,
  viewPortalClients: true,
  viewPortalProfiles: true,
  viewPortalProjects: true,
  viewProfilesComments: true,
  viewProfilesFiles: true,
  viewProfilesNotifications: true,
  viewProfilesShortlists: true,
  viewVacancyComments: true,
};
export const mockedTutorialProgress: TutorialProgress = {
  engaging: {
    createMessageTemplate: true,
    contactFromPlugin: false,
    contactFromTSP: false,
  },
  initialization: {
    createVacancy: false,
    publicVacancy: true,
  },
  managing: {
    pipelines: false,
    kanban: false,
    hire: true,
  },
  sourcing: {
    findContactsViaPlugin: true,
    useRecommendations: false,
    addCandidateToDatabase: true,
    useSearchConsole: false,
    addCandidateToVacancy: false,
    saveSearchParameters: false,
  },
};

export const mockedBreezyCompanies = [
  { id: '6632dbbb0a93', name: 'AOG Experts', createdAt: '2023-01-26T15:05:09.543Z' },
  { id: '709bc1ba6a5701', name: 'Salee', createdAt: '2023-03-14T11:32:27.838Z' },
  { id: 'af553f9d4f9d01', name: 'TSP', createdAt: '2023-02-20T10:31:36.474Z' },
];
