import { CdkScrollable } from '@angular/cdk/scrolling';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatTab, MatTabGroup, MatTabLabel } from '@angular/material/tabs';
import { MatTooltip } from '@angular/material/tooltip';
import { AddNewEntitySystemActions } from '@app-core/add-new-entity-popup/actions/new-entity.system.actions';
import { AddNewEntityUserActions } from '@app-core/add-new-entity-popup/actions/new-entity.user.actions';
import {
  getVacancyPublicDescription,
  getVacancySuggestedParams,
  isLoading,
} from '@app-core/add-new-entity-popup/reducers/new-entity.reducer';
import { RootSystemActions } from '@app-core/root/actions/root.system.actions';
import {
  getPublicVacancyUrl,
  getUserId,
  getUserIsAdmin,
  getUserLocalization,
  isUserOptionAllowed,
  portalSupportUrl,
} from '@app-core/root/reducer/root.reducer';
import { environment } from '@app-environment/environment';
import { getIntegrationCustomFieldId } from '@app-integration/kernel/helpers';
import { InternalNotificationActions } from '@app-shared/actions/internal-notification.actions';
import { checkUnresolvedDictionaries } from '@app-shared/functions/missed-dictionary-calculation/missed-dictionary-calculation';
import { isNotNullOrUndefined } from '@app-shared/functions/utilities/utilities';
import { OWL_DATE_TIME_FORMATS } from '@app-shared/helpers';
import {
  AllDictionaries,
  ClientShort,
  CurrentUser,
  CustomDialogTab,
  CustomForm,
  CustomFormControl,
  CustomFormFieldSettings,
  DictionaryCity,
  DictionaryDepartment,
  DictionaryItem,
  DictionaryShortListBoard,
  DirectoryFieldType,
  Locales,
  NewEntityPopupMode,
  NewVacancyDialogForm,
  SimpleRecruiter,
  UnaryOperator,
  VacancyCreatePayload,
  VacancyDetails,
  VacancyEditPayload,
  VacancySuggestedParams,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import {
  getCustomFields,
  getDepartments,
  getDictionaryLoadingState,
  getFormConfigs,
  getRecruiters,
  getShortListBoards,
  getVacancyEditingDictionaries,
} from '@app-shared/reducers/dictionary/dictionary.reducer';
import { getVacancyDetails } from '@app-shared/reducers/vacancy/vacancy.reducer';
import {
  DateTimeAdapter,
  NativeDateTimeAdapter,
  OWL_DATE_TIME_FORMATS as OWL_DATE_TIME_FORMATS_PROVIDER,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { Store, select } from '@ngrx/store';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { AutocompleteSelectComponent } from '@tsp-components/autocomplete-select';
import { ConfirmationDialogComponent } from '@tsp-components/confirmation-dialog';
import { MultipleSelectSearchComponent } from '@tsp-components/multiple-select-search';
import { RichEditorComponent } from '@tsp-components/rich-editor';
import { SpinnerComponent } from '@tsp-components/spinner';
import { TagInputComponent } from '@tsp-components/tag-input';
import { FallbackSrcWidthDirective, LimitInputDirective } from '@tsp-directives';
import {
  ConvertIdToNamePipe,
  GetFormControlPipe,
  GetInitialsPipe,
  TranslateDictionaryNamePipe,
} from '@tsp-pipes';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import {
  all,
  anyPass,
  append,
  differenceWith,
  equals,
  find,
  flatten,
  flip,
  has,
  includes,
  innerJoin,
  isEmpty,
  isNil,
  mapObjIndexed,
  not,
  path,
  pathOr,
  pipe,
  pluck,
  prop,
  propEq,
  propOr,
  filter as ramdaFilter,
  reject,
  replace,
  map as rmap,
  sortBy,
  test,
  trim,
  uniq,
  update,
  values,
  without,
} from 'ramda';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { CustomFieldsGroupComponent } from '../../../custom-fields/custom-fields-group/custom-fields-group.component';
import { GoogleAutocompleteComponent } from '../google-autocomplete/google-autocomplete.component';
import { SuggestedParamsComponent } from '../suggested-params/suggested-params.component';

interface VacancySkillPayload {
  id?: number;
  skillId: number;
  main: boolean;
}

type VacancyPayload = VacancyCreatePayload | VacancyEditPayload;

@Component({
  imports: [
    AsyncPipe,
    AutocompleteSelectComponent,
    CdkScrollable,
    CdkTextareaAutosize,
    ConvertIdToNamePipe,
    CustomFieldsGroupComponent,
    FallbackSrcWidthDirective,
    GetFormControlPipe,
    GetInitialsPipe,
    GoogleAutocompleteComponent,
    LimitInputDirective,
    MatButton,
    MatCheckbox,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatError,
    MatFormField,
    MatIconButton,
    MatHint,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatSelectTrigger,
    MatTab,
    MatTabGroup,
    MatTabLabel,
    MatTooltip,
    MultipleSelectSearchComponent,
    NgClass,
    NgStyle,
    NgxIntlTelInputModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    RichEditorComponent,
    SpinnerComponent,
    SuggestedParamsComponent,
    TagInputComponent,
    TranslateDictionaryNamePipe,
    TranslatePipe,
    TranslateDirective,
  ],
  providers: [
    { provide: DateTimeAdapter, useClass: NativeDateTimeAdapter },
    { provide: OWL_DATE_TIME_FORMATS_PROVIDER, useValue: OWL_DATE_TIME_FORMATS },
  ],
  selector: 'app-new-vacancy-dialog',
  templateUrl: './new-vacancy-dialog.component.html',
  styleUrls: ['./new-vacancy-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
})
export class NewVacancyDialogComponent implements OnInit, OnDestroy {
  public allowDeleteVacancy$?: Observable<boolean>;
  public departments$?: Observable<DictionaryDepartment[]>;
  public hasAccessToClients$: Observable<boolean>;
  public hasAccessToCustomFields$: Observable<boolean>;
  public hasAccessToSalary$: Observable<boolean>;
  public forbiddenEditVacancyHotStatus$: Observable<boolean>;
  public isLoading$: Observable<boolean>;
  public isUserAdmin$?: Observable<boolean>;
  public supportUrl$?: Observable<string>;
  public clients: ClientShort[] = [];
  public dictionaries?: Partial<AllDictionaries>;
  public publicVacancyUrl$?: Observable<string>;
  public recruiters?: SimpleRecruiter[];
  // public selectedShortListBoard?: DictionaryShortListBoard;
  public shortListBoards?: DictionaryShortListBoard[];
  public userLocale$: Observable<Locales>;
  public vacancyCustomFields?: DirectoryFieldType[];

  public allowEditVacancyCloseDate: boolean;
  public formConfigs: CustomForm;
  public renderDetailsInformation: boolean;
  public newVacancyForm: FormGroup<NewVacancyDialogForm>;
  public limitRegExp = new RegExp(/\D/g);
  public securityGroups: DictionaryItem[] = [];
  public showRequiredMessage = false;
  public defaultBoardId = 2;
  public currencies = ['USD', 'EUR', 'UAH'];
  public vacancyAdditionalDateFields = [
    {
      name: 'add-new-entity.new-vacancy.form.first-hired',
      control: 'firstHireAt',
    },
    {
      name: 'add-new-entity.new-vacancy.form.first-cv',
      control: 'firstCvSentAt',
    },
    {
      name: 'add-new-entity.new-vacancy.form.first-interview',
      control: 'firstInterviewAt',
    },
    {
      name: 'add-new-entity.new-vacancy.form.success-closed',
      control: 'successClosedDate',
    },
    {
      name: 'add-new-entity.new-vacancy.form.unsuccess-closed',
      control: 'unsuccessClosedDate',
    },
  ];
  public selectedTemplate = 0;
  public vacancySuggestedParams: VacancySuggestedParams;
  public currentUser: Omit<
    CurrentUser,
    'additionalInfo' | 'availableOptions' | 'availablePages' | 'mailHeader' | 'mailFooter' | 'roles'
  >;
  public requiredCustomFields: CustomFormFieldSettings[];
  public requiredCustomFieldsForPublic: CustomFormFieldSettings[];
  public selectedRecruiter: SimpleRecruiter;
  public vacancyDescription: string | null;
  private vacancyDetails: VacancyDetails;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      mode: NewEntityPopupMode;
      parentParams?: string;
    },
    private readonly formBuilder: FormBuilder,
    private readonly store$: Store<State>,
    private readonly dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    this.initForm();

    // Disabled due to TSP-7622
    // this.newVacancyForm
    //   .get('board')
    //   .valueChanges.pipe(
    //     filter((id) => !isNil(id) && !equals(id, prop('id', this.selectedShortListBoard))),
    //     distinctUntilChanged(),
    //     takeUntil(this.ngUnsubscribe),
    //   )
    //   .subscribe((id: number) => {
    //     this.store$.dispatch(AddNewEntityUserActions.GetShortListBoardByIdAction({ id }));
    //   });

    this.store$.dispatch(AddNewEntitySystemActions.OpenVacancyDialogOnInitAction());

    this.departments$ = this.store$.pipe(select(getDepartments));

    this.isLoading$ = combineLatest([
      this.store$.pipe(select(getDictionaryLoadingState)),
      this.store$.pipe(select(isLoading)),
    ]).pipe(map(([dictionaryLoading, dataLoading]: boolean[]) => dictionaryLoading || dataLoading));

    this.isUserAdmin$ = this.store$.pipe(select(getUserIsAdmin));

    this.allowDeleteVacancy$ = this.store$.pipe(select(isUserOptionAllowed('deleteVacancy')));

    this.hasAccessToClients$ = this.store$.pipe(select(isUserOptionAllowed('clientsPage')));

    this.hasAccessToCustomFields$ = this.store$.pipe(
      select(isUserOptionAllowed('showVacancyCustomFields')),
    );

    this.forbiddenEditVacancyHotStatus$ = this.store$.pipe(
      select(isUserOptionAllowed('editVacancyHotStatus')),
      map(not),
    );

    this.hasAccessToSalary$ = this.store$.pipe(select(isUserOptionAllowed('showSalary')));

    this.publicVacancyUrl$ = this.store$.pipe(select(getPublicVacancyUrl), shareReplay());

    this.store$
      .pipe(select(isUserOptionAllowed('editVacancyCloseDate')), takeUntil(this.ngUnsubscribe))
      .subscribe((state) => {
        this.allowEditVacancyCloseDate = state;
      });

    this.processAsyncData();

    this.userLocale$ = this.store$.pipe(select(getUserLocalization), shareReplay());
    this.supportUrl$ = this.store$.pipe(select(portalSupportUrl), shareReplay());

    this.store$
      .pipe(select(getVacancySuggestedParams), takeUntil(this.ngUnsubscribe))
      .subscribe((params) => (this.vacancySuggestedParams = params));

    this.store$
      .pipe(
        select(getVacancyPublicDescription),
        filter(isNotNullOrUndefined),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((descriptions) => {
        const customFieldsControl = this.newVacancyForm.get('customFields');

        if (environment.integrations.kernel) {
          Object.keys(descriptions).forEach((key) => {
            const propertyId = getIntegrationCustomFieldId(key, 'vacancy');
            const existedCustomField = customFieldsControl.value.find(
              (cf) => cf.type.id === propertyId,
            );

            if (existedCustomField) {
              const existedCustomFieldIndex = customFieldsControl.value.findIndex(
                (cf) => cf.type.id === propertyId,
              );
              const updatedCustomField = { ...existedCustomField, value: descriptions[key] };
              const updatedCustomFields = update(
                existedCustomFieldIndex,
                updatedCustomField,
                customFieldsControl.value,
              );
              customFieldsControl.setValue(updatedCustomFields);

              return;
            }

            const fieldName = this.vacancyCustomFields.find((cf) => cf.id === propertyId)?.name;

            const newCustomField = {
              value: descriptions[key],
              type: {
                id: propertyId,
                name: fieldName || key,
                type: 'longtext',
                showInPublicWebsite: true,
                showInPanel: true,
              },
            };

            const updatedCustomFields = [...customFieldsControl.value, newCustomField];
            customFieldsControl.setValue(updatedCustomFields);
          });

          return;
        }

        this.newVacancyForm.patchValue({
          description: values(descriptions).join('\n\r'),
        });
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public controlHasErrorType(controlName: string, errorName: string): boolean {
    const control = this.newVacancyForm.get(controlName);

    if (!control) {
      return false;
    }

    return control.touched && (control.errors ? (control.errors[errorName] as boolean) : false);
  }
  public get currentSkills(): { skills: number[] } {
    return {
      skills: this.newVacancyForm.get('skills').value || [],
    };
  }
  public get rawDescription() {
    const description: string = this.newVacancyForm.get('description').value || '';
    return pipe(replace(/(<([^>]+)>)/gi, ''), trim)(description);
  }
  public get isDescriptionValue(): boolean {
    return test(/\S/, this.rawDescription);
  }
  public isPristine(): boolean {
    return propOr(true, 'pristine', this.newVacancyForm);
  }
  public get isTitleValueEmpty() {
    return anyPass([isNil, isEmpty])(this.newVacancyForm.get('title').value);
  }
  public get selectedCity(): number {
    return this.newVacancyForm.get('city').value;
  }
  public get selectedClients(): ClientShort[] {
    return innerJoin(
      (client, clientId) => client.id === clientId,
      this.clients,
      this.newVacancyForm.get('clients').value,
    );
  }
  public get shareToRecruiters(): SimpleRecruiter[] {
    return this.newVacancyForm.get('shareTo').value;
  }
  public get showSaveButton(): boolean {
    const form = this.newVacancyForm;

    const isTitleFilled = !this.isTitleValueEmpty;
    const candidatesQuantityValid = form.get('candidatesQuantity').valid;
    const createdAtValid = this.data.mode === 'EDIT' ? form.get('createdAt').valid : true;
    const allNecessaryFieldsFilled = this.missedRequiredFields.length === 0;

    return isTitleFilled && candidatesQuantityValid && createdAtValid && allNecessaryFieldsFilled;
  }
  public get showMagicButton(): number {
    const form = this.newVacancyForm;
    return (
      form.get('skills').value.length || form.get('mainSkill').value || form.get('speciality').value
    );
  }
  public get vacancyRegion(): number | null {
    const selectedCityId = this.newVacancyForm.get('city').value;

    if (!selectedCityId) {
      return null;
    }

    const city = find(propEq(selectedCityId, 'id'), this.dictionaries?.cities) as DictionaryCity;

    return city?.region || null;
  }
  public get vacancyTypeForm(): FormArray<FormControl<boolean>> {
    return this.newVacancyForm.get('typeVacancy') as FormArray<FormControl<boolean>>;
  }
  public get vacancyTypeFormControls(): AbstractControl[] {
    return this.vacancyTypeForm.controls;
  }
  public analyzeVacancyParams() {
    const title = this.newVacancyForm.get('title').value;

    if (title) {
      this.store$.dispatch(
        AddNewEntityUserActions.AnalyzeTitleAndDescription({
          title,
          description: this.rawDescription || null,
        }),
      );
    }
  }
  public assignRecruiter(id: number) {
    this.selectedRecruiter = find(propEq(id, 'id'), this.recruiters) as SimpleRecruiter;
  }
  // Custom form fields logic
  public get customFieldsDefinitions(): CustomFormFieldSettings[] {
    const customFormControlData = this.getControlPositioning('customFields');
    return pipe(propOr([], 'fields'))(customFormControlData);
  }
  public get isCustomFieldsInvalid(): boolean {
    const formIsTouched = !this.isPristine();
    const allNecessaryFieldsFilled = this.missedRequiredFields.length === 0;

    return formIsTouched && !allNecessaryFieldsFilled;
  }

  public get missedRequiredFields(): string[] {
    const isPostToListing = this.newVacancyForm.get('postToListing').value;
    const customFields = this.newVacancyForm.get('customFields').value;
    const invalidFieldsNames: string[] = [];

    const fieldsToCheck = isPostToListing
      ? [...this.requiredCustomFields, ...this.requiredCustomFieldsForPublic]
      : this.requiredCustomFields;

    if (!customFields?.length || !fieldsToCheck?.length) {
      return invalidFieldsNames;
    }

    const filledFields = innerJoin((a, b) => a?.type?.id === b?.id, customFields, fieldsToCheck);

    const missedFields = differenceWith(
      (a, b) => a?.id === b?.type?.id,
      fieldsToCheck,
      filledFields,
    );

    if (missedFields.length) {
      missedFields.forEach((field) => {
        const fieldName = this.vacancyCustomFields.find((cf) => field.id === cf.id)?.name;
        invalidFieldsNames.push(fieldName);
      });
    }

    const isValueNull = (val: boolean | string | number | string[], key: string) =>
      includes(key, ['array', 'int', 'bool', 'text', 'date', 'longtext']) &&
      anyPass([isNil, isEmpty])(val);

    filledFields.forEach((field) => {
      if (isValueNull(field.value, field.type.type)) {
        invalidFieldsNames.push(field.type.name);
      }
    });

    return invalidFieldsNames;
  }
  public getControlPositioning(controlName: string): CustomFormControl {
    return pipe(
      propOr([], 'tabs'),
      find(propEq(this.selectedTemplate, 'index')),
      propOr([], 'controls'),
      find(propEq(controlName, 'name')),
    )(this.formConfigs) as CustomFormControl;
  }
  public generatePublicDescriptionWithAI(): void {
    this.store$.dispatch(
      AddNewEntityUserActions.GenerateVacancyPublicDescriptionAction({
        id: +this.data.parentParams,
      }),
    );
  }
  public isCloseDateFieldReadonly(fieldName: string): boolean {
    return ['successClosedDate', 'unsuccessClosedDate'].includes(fieldName)
      ? !this.allowEditVacancyCloseDate
      : false;
  }
  public isSuggestedParams(param: keyof VacancySuggestedParams): boolean {
    return pipe(prop(param), anyPass([isNil, isEmpty]), not)(this.vacancySuggestedParams);
  }
  public publishVacancy(goToSearch: boolean) {
    const postToListing = this.newVacancyForm.get('postToListing').value;
    if (!this.isDescriptionValue && postToListing) {
      this.showWarningMessage('add-new-entity.new-vacancy.warning.fill-description');
    } else if (!this.newVacancyForm.get('assignedRecruiter').value) {
      this.showWarningMessage('add-new-entity.new-vacancy.warning.select-recruiter');
    } else if (this.data.mode === 'EDIT') {
      this.submit(false);
    } else {
      this.submit(goToSearch);
    }
  }
  public manageStatus() {
    this.showWarningMessage('add-new-entity.new-vacancy.warning.changes');
  }
  public openClosePopup() {
    if (this.isPristine()) {
      this.dialog.closeAll();
    } else {
      this.store$.dispatch(RootSystemActions.OpenConfirmationDialogForClosePopupAction());
    }
  }
  public openDeletePopup() {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: { text: 'add-new-entity.new-vacancy.confirmation-dialog.delete' },
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.store$.dispatch(
            AddNewEntityUserActions.DeleteVacancyFromPopupAction({ id: this.vacancyDetails.id }),
          );
        }
      });
  }
  public changeTemplate(templateIndex: number) {
    this.selectedTemplate = templateIndex;
    const shouldAnalyze = !this.isTitleValueEmpty && isNil(this.vacancySuggestedParams);
    if (shouldAnalyze) {
      this.analyzeVacancyParams();
    }
  }
  public onRenderDetailsInformation() {
    this.renderDetailsInformation = !this.renderDetailsInformation;
  }
  public removeRecruiterFromSharing(recruiter: SimpleRecruiter) {
    const control: AbstractControl = this.newVacancyForm.get('shareTo');
    control.setValue(without([recruiter], control.value as SimpleRecruiter[]));
    this.sortRecruiters();
  }
  public removeSelectedClient(client: ClientShort) {
    const control: AbstractControl = this.newVacancyForm.get('clients');
    control.setValue(without([client.id], control.value as number[]));
    this.sortClients();
  }
  public removeSuggestion(name: keyof VacancySuggestedParams) {
    this.store$.dispatch(
      AddNewEntityUserActions.ModifyVacancySuggestionAction({ name, value: null }),
    );
  }
  public sanitizeSkills(mainSkill: number) {
    const skills: number[] = pathOr([], ['value', 'skills'])(this.newVacancyForm);
    if (includes(mainSkill, skills)) {
      const newSkills = without([mainSkill], skills);
      this.newVacancyForm.get('skills').setValue(newSkills);
    }
  }
  public selectSuggestion(id: number, controlName: string, name: keyof VacancySuggestedParams) {
    let controlValue: number | number[] | boolean[];

    switch (controlName) {
      case 'typeVacancy':
        controlValue = this.updateTypeVacancyWithData([id]);
        break;
      case 'skills': {
        const actionValue = pipe(propOr([], 'skills'), without([id]))(this.vacancySuggestedParams);
        const skillsControl = this.newVacancyForm.get('skills');
        controlValue = pipe(
          propOr([], 'value') as UnaryOperator<AbstractControl, number[]>,
          append(id),
          uniq,
        )(skillsControl);
        this.store$.dispatch(
          AddNewEntityUserActions.ModifyVacancySuggestionAction({ name, value: actionValue }),
        );
        break;
      }
      case 'mainSkill':
      case 'city':
      case 'speciality': {
        controlValue = id;
        const actionValue = pipe(
          propOr([], controlName),
          without([id]),
        )(this.vacancySuggestedParams);
        this.store$.dispatch(
          AddNewEntityUserActions.ModifyVacancySuggestionAction({ name, value: actionValue }),
        );
        break;
      }
      case 'durationTime': {
        controlValue = id;
        this.store$.dispatch(
          AddNewEntityUserActions.ModifyVacancySuggestionAction({ name, value: null }),
        );
        break;
      }
      default:
        controlValue = id;
    }
    this.newVacancyForm.patchValue({
      [controlName]: controlValue,
    });
    if (controlName === 'mainSkill') {
      this.sanitizeSkills(id);
    }
  }
  public sortClients() {
    if (this.selectedClients?.length && this.clients.length) {
      this.clients = sortBy((option) => !includes(option, this.selectedClients), this.clients);
    }
  }
  public sortRecruiters() {
    if (this.shareToRecruiters && this.recruiters) {
      this.recruiters = sortBy(
        (option) => !includes(option, this.shareToRecruiters),
        this.recruiters,
      );
    }
  }
  public submit(goToSearch = false) {
    if (this.isTitleValueEmpty) {
      this.showRequiredMessage = true;
      this.checkFormErrors();
      return;
    }

    const payload = this.payload;

    if (this.data.mode === 'EDIT') {
      this.store$.dispatch(
        AddNewEntityUserActions.UpdateVacancyAction({
          vacancy: { ...payload, id: this.vacancyDetails.id },
        }),
      );
    } else {
      this.store$.dispatch(
        AddNewEntityUserActions.CreateNewVacancyAction({ data: payload, goToSearch }),
      );
    }
  }
  public trackById(
    _index: number,
    element: SimpleRecruiter | ClientShort | DictionaryItem | DictionaryDepartment,
  ) {
    return element.id;
  }
  public updateCity({ city, country }: { city: number | null; country: number | null }) {
    this.newVacancyForm.patchValue({
      city,
      country,
    });
  }
  public updateSkills(newSkills: { skills?: number[] }) {
    this.newVacancyForm.patchValue(newSkills);
  }

  private checkFormErrors() {
    const controls = this.newVacancyForm.controls;
    for (const control in controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (controls.hasOwnProperty(control)) {
        const currentControl: FormControl<unknown> = controls[control];
        if (!isNil(currentControl.validator) && currentControl.untouched) {
          currentControl.markAsTouched();
        }
      }
    }
  }
  private generateVacancyTypeControl(
    vacancyTypes: DictionaryItem[],
  ): FormArray<FormControl<boolean>> {
    const typeControls = rmap(() => new FormControl<boolean>(false), vacancyTypes);
    return new FormArray<FormControl<boolean>>(typeControls);
  }
  private get payload(): VacancyPayload {
    const formValue = this.newVacancyForm.value;
    const isEditing = this.data.mode === 'EDIT';
    const numbersOfTalent = formValue.candidatesQuantity ? +formValue.candidatesQuantity : 1;

    let department = formValue.department;

    if (environment.integrations.kernel) {
      const departmentCustomField = environment.integrations.customFields.vacancy.find(
        (field) => field.name === 'departmentId',
      );

      if (departmentCustomField?.id) {
        const departmentField = formValue.customFields.find(
          (cfv) => cfv.type.id === departmentCustomField?.id,
        );

        department = departmentField?.value as string;
      }
    }

    return {
      assignedId: formValue.assignedRecruiter ? { id: formValue.assignedRecruiter } : null,
      board: formValue.board ? { id: formValue.board } : null,
      city: formValue.city ? { id: formValue.city } : null,
      clients: formValue.clients ? formValue.clients.map((id) => ({ id })) : null,
      country: formValue.country ? { id: formValue.country } : null,
      createdAt: isEditing ? formValue.createdAt : null,
      currency: formValue.currency,
      currentStatus: isEditing ? formValue.status : 2,
      description: formValue.description,
      deadlineDate: formValue.deadlineDate,
      department,
      duration: formValue.durationTime,
      fieldValues: formValue.customFields,
      firstHireAt: isEditing ? formValue.firstHireAt : null,
      firstCvSentAt: isEditing ? formValue.firstCvSentAt : null,
      firstInterviewAt: isEditing ? formValue.firstInterviewAt : null,
      hotVacancy: formValue.hotVacancy,
      inside_information: formValue.insideInformation,
      name: formValue.title,
      numbersOfTalent,
      rate: formValue.rate,
      salary: formValue.salary,
      salaryComment: formValue.salaryComment,
      showInPublicWebsite: formValue.postToListing,
      recruiterFavorites: formValue.shareTo,
      skills: isEditing ? this.existedSkillsForSymfony : this.newSkillsForSymfony,
      speciality: formValue.speciality ? { id: formValue.speciality } : null,
      startDate: formValue.startDate,
      successClosedDate: formValue.successClosedDate,
      types: this.updateTypeVacancy(formValue.typeVacancy),
      unsuccessClosedDate: formValue.unsuccessClosedDate,
    };
  }
  private get existedSkillsForSymfony(): VacancySkillPayload[] {
    const selectedSkills = pathOr([], ['value', 'skills'], this.newVacancyForm) as number[];
    const existedSkills = pipe(
      propOr([], 'skills') as UnaryOperator<VacancyDetails, VacancySkillPayload[]>,
      reject(propEq(true, 'main')) as UnaryOperator<VacancySkillPayload[], VacancySkillPayload[]>,
    )(this.vacancyDetails);
    const existedSkillsIds = pluck('skillId', existedSkills);
    const notModifiedSkills = innerJoin(
      (skillObj, id) => skillObj.skillId === id,
      existedSkills,
      selectedSkills,
    );
    const newSkills = pipe(
      reject(flip(includes)(existedSkillsIds) as UnaryOperator<unknown, boolean>) as UnaryOperator<
        number[],
        number[]
      >,
      rmap((id: number) => ({ skillId: id, main: false })) as UnaryOperator<
        number[],
        VacancySkillPayload[]
      >,
    )(selectedSkills);

    const selectedMainSkill: number = path(['value', 'mainSkill'], this.newVacancyForm);
    const existedMainSkill = pipe(
      propOr([], 'skills'),
      find(propEq(true, 'main')),
    )(this.vacancyDetails) as VacancySkillPayload;

    switch (true) {
      case selectedMainSkill === existedMainSkill?.skillId:
        return [existedMainSkill, ...notModifiedSkills, ...newSkills];
      case !!selectedMainSkill:
        return [{ skillId: selectedMainSkill, main: true }, ...notModifiedSkills, ...newSkills];
      default:
        return [...notModifiedSkills, ...newSkills];
    }
  }
  private get newSkillsForSymfony(): VacancySkillPayload[] {
    const formValue = this.newVacancyForm.value;
    const defaultSkills = pipe(
      propOr([], 'skills'),
      rmap((id: number) => ({ skillId: id, main: false })),
    )(formValue);
    const mainSkill = formValue.mainSkill ? [{ skillId: formValue.mainSkill, main: true }] : [];
    return [...mainSkill, ...defaultSkills];
  }
  private showWarningMessage(message: string): void {
    this.store$.dispatch(InternalNotificationActions.WarningNotificationAction({ message }));
  }
  private initForm() {
    this.newVacancyForm = this.formBuilder.group<NewVacancyDialogForm>(
      {
        assignedRecruiter: new FormControl(null, [Validators.required]),
        board: new FormControl(null, [Validators.required]),
        candidatesQuantity: new FormControl(1, [
          Validators.min(1),
          Validators.max(99),
          Validators.required,
        ]),
        city: new FormControl(null),
        clients: new FormControl([]),
        country: new FormControl(null),
        createdAt: new FormControl(null, [Validators.required]),
        currency: new FormControl(null),
        customFields: new FormControl([]),
        deadlineDate: new FormControl(null),
        description: new FormControl(null),
        department: new FormControl(null),
        durationTime: new FormControl(null),
        firstHireAt: new FormControl(null),
        firstCvSentAt: new FormControl(null),
        firstInterviewAt: new FormControl(null),
        hotVacancy: new FormControl(false),
        insideInformation: new FormControl(null),
        mainSkill: new FormControl(null),
        postToListing: new FormControl(false),
        rate: new FormControl(null),
        salary: new FormControl(null),
        salaryComment: new FormControl(null),
        shareTo: new FormControl([]),
        skills: new FormControl([]),
        speciality: new FormControl(null),
        startDate: new FormControl(null),
        status: new FormControl(2, [Validators.required]),
        successClosedDate: new FormControl(null),
        title: new FormControl(null, [Validators.required]),
        typeVacancy: new FormArray([]),
        unsuccessClosedDate: new FormControl(null),
      },
      { updateOn: 'change' },
    );

    this.newVacancyForm.statusChanges
      .pipe(
        distinctUntilChanged(),
        filter((status) => status && this.showRequiredMessage === true),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => (this.showRequiredMessage = false));
    this.newVacancyForm
      .get('mainSkill')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: number) => this.sanitizeSkills(value));
  }
  private processAsyncData(): void {
    const dictionaries$ = this.store$.pipe(select(getVacancyEditingDictionaries)).pipe(
      filter((dictionaries) => isEmpty(checkUnresolvedDictionaries(dictionaries, 'newVacancy'))),
      tap((dictionaries) => {
        this.dictionaries = dictionaries;
        this.clients = dictionaries.clients;
        this.securityGroups = dictionaries?.securityGroups || [];
        this.newVacancyForm.controls.typeVacancy = this.generateVacancyTypeControl(
          this.dictionaries.vacancyType,
        );
      }),
      map(() => true),
      distinctUntilChanged(),
    );
    const formConfigs$ = this.store$.pipe(select(getFormConfigs)).pipe(
      filter((configs) => !isNil(configs)),
      tap((configs) => {
        this.formConfigs = find(propEq('vacancyDialog', 'target'))(configs) as CustomForm;
        this.requiredCustomFields = this.getRequiredCustomFields(false);
        this.requiredCustomFieldsForPublic = this.getRequiredCustomFields(true);
      }),
      map(() => true),
      distinctUntilChanged(),
    );
    const recruiters$ = this.store$.pipe(select(getRecruiters)).pipe(
      filter((recruiters) => !isEmpty(recruiters)),
      tap((recruiters) => {
        this.recruiters = recruiters;
      }),
      map(() => true),
      distinctUntilChanged(),
    );
    const boards$ = this.store$.pipe(select(getShortListBoards)).pipe(
      filter((boards) => !isEmpty(boards)),
      tap((boards) => {
        this.shortListBoards = boards;
        // this.selectedShortListBoard = find(propEq(this.defaultBoardId, 'id'), boards);
      }),
      map(() => true),
      distinctUntilChanged(),
    );
    const customFields$ = this.store$.pipe(select(getCustomFields('projectCustomFields'))).pipe(
      filter((customFields) => !anyPass([isNil, isEmpty])(customFields)),
      distinctUntilChanged(),
      tap((customFields) => {
        this.vacancyCustomFields = ramdaFilter((field) => field.showInPanel, customFields);
      }),
      map(() => true),
      distinctUntilChanged(),
    );

    const formIsInitialized$ = combineLatest([
      dictionaries$,
      formConfigs$,
      recruiters$,
      boards$,
      customFields$,
    ]).pipe(
      filter(all(equals(true))),
      map(() => true),
      distinctUntilChanged(),
      switchMap(() => this.store$.pipe(select(getUserId))),
      tap((userId) => {
        const boardId: number = path(['0', 'id'], this.shortListBoards);
        this.newVacancyForm.patchValue({
          board: boardId,
          assignedRecruiter: userId,
        });
      }),
      map(() => true),
      distinctUntilChanged(),
    );

    combineLatest([formIsInitialized$, of(this.data.mode !== 'CREATE')])
      .pipe(
        filter(all(equals(true))),
        tap(() =>
          this.store$.dispatch(
            AddNewEntitySystemActions.RequestVacancyDetailsOnInitAction({
              id: +this.data.parentParams,
            }),
          ),
        ),
        switchMap(() => this.store$.pipe(select(getVacancyDetails))),
        filter(
          (vacancy) => isNotNullOrUndefined(vacancy) && vacancy?.id === +this.data.parentParams,
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((vacancyDetails: VacancyDetails) => {
        this.vacancyDetails = vacancyDetails;
        this.updateFormWithData(this.vacancyDetails);
        if (pathOr(null, ['assignedId', 'id'], vacancyDetails)) {
          this.selectedRecruiter = find(
            propEq(vacancyDetails.assignedId.id, 'id'),
            this.recruiters,
          ) as SimpleRecruiter;
        }
      });
  }
  private updateFormWithData(vacancy: VacancyDetails) {
    this.newVacancyForm.setValue({
      assignedRecruiter: vacancy.assignedId.id || null,
      board: vacancy.board.id || null,
      candidatesQuantity: vacancy.numbersOfTalent || 1,
      city: vacancy.city?.id || null,
      clients: vacancy.clients?.map(({ id }) => id) || null,
      country: vacancy.country?.id || null,
      createdAt: vacancy.createdAt || null,
      currency: vacancy.currency || null,
      customFields: vacancy.fieldValues || null,
      deadlineDate: vacancy.deadlineDate || null,
      description: vacancy.description || null,
      department: vacancy.department || null,
      durationTime: vacancy.duration || null,
      firstHireAt: vacancy.firstHireAt || null,
      firstCvSentAt: vacancy.firstCvSentAt || null,
      firstInterviewAt: vacancy.firstInterviewAt || null,
      hotVacancy: vacancy.hotVacancy || false,
      insideInformation: vacancy.inside_information || null,
      mainSkill: pipe(
        propOr([], 'skills'),
        find(propEq(true, 'main')),
        propOr(null, 'skillId') as UnaryOperator<VacancySkillPayload, number>,
      )(vacancy),
      postToListing: vacancy.showInPublicWebsite,
      rate: vacancy.rate || null,
      salary: vacancy.salary || null,
      salaryComment: vacancy.salaryComment || null,
      shareTo: vacancy.recruiterFavorites.map((recruiter: SimpleRecruiter) => {
        return find(propEq(recruiter.id, 'id'), this.recruiters) as SimpleRecruiter;
      }),
      skills: pipe(
        propOr([], 'skills') as UnaryOperator<VacancyDetails, VacancySkillPayload[]>,
        reject(propEq(true, 'main')) as UnaryOperator<VacancySkillPayload[], VacancySkillPayload[]>,
        pluck('skillId') as UnaryOperator<VacancySkillPayload[], number[]>,
      )(vacancy),
      speciality: vacancy.speciality?.id || null,
      startDate: vacancy.startDate || null,
      status: vacancy.currentStatus || null,
      successClosedDate: vacancy.successClosedDate || null,
      title: vacancy.name || null,
      typeVacancy: this.updateTypeVacancyWithData(prop('types', vacancy)),
      unsuccessClosedDate: vacancy.unsuccessClosedDate || null,
    });
  }
  private updateTypeVacancy(typeVacancy: boolean[] | number[]): number[] {
    return pipe(
      mapObjIndexed((value: boolean, index: string) =>
        value ? (this.dictionaries.vacancyType[index] as DictionaryItem).id : null,
      ) as unknown as UnaryOperator<boolean[], unknown>,
      values as UnaryOperator<unknown, (number | null)[]>,
      reject(isNil) as UnaryOperator<(number | null)[], number[]>,
    )(typeVacancy as boolean[]);
  }
  private updateTypeVacancyWithData(typeVacancy: (number | boolean)[] | null): boolean[] {
    return typeVacancy
      ? rmap(
          (value: DictionaryItem) => includes(value.id, typeVacancy),
          this.dictionaries.vacancyType,
        )
      : this.newVacancyForm.get('typeVacancy').value;
  }
  // Kernel logic
  private getRequiredCustomFields(checkForPublic: boolean): CustomFormFieldSettings[] {
    const requiredProperty: keyof CustomFormFieldSettings = checkForPublic
      ? 'requiredForPublic'
      : 'required';

    return pipe(
      propOr([], 'tabs') as UnaryOperator<CustomForm, CustomDialogTab[]>,
      pluck('controls'),
      flatten,
      pluck('fields') as UnaryOperator<CustomFormControl[], CustomFormFieldSettings[][]>,
      flatten,
      ramdaFilter(
        has(requiredProperty) as UnaryOperator<CustomFormFieldSettings, boolean>,
      ) as UnaryOperator<CustomFormFieldSettings[], CustomFormFieldSettings[]>,
    )(this.formConfigs);
  }
}
