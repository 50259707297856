import { CdkScrollable } from '@angular/cdk/scrolling';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import {
  MatError,
  MatFormField,
  MatLabel,
  MatPrefix,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { CustomFieldsGroupComponent } from '@app-core/custom-fields/custom-fields-group/custom-fields-group.component';
import { OWL_DATE_TIME_FORMATS } from '@app-shared/helpers';
import {
  BidForm,
  DictionaryItem,
  DictionaryShortList,
  DictionaryShortListCodes,
  DirectoryCustomFieldResponse,
  DirectoryFieldType,
  ShortList,
  UnaryOperator,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { getShortListSources } from '@app-shared/reducers/dictionary/dictionary.reducer';
import { numberValidator } from '@app-shared/validators';
import {
  DateTimeAdapter,
  NativeDateTimeAdapter,
  OWL_DATE_TIME_FORMATS as OWL_DATE_TIME_FORMATS_PROVIDER,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { Store, select } from '@ngrx/store';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { AutocompleteSelectComponent } from '@tsp-components/autocomplete-select';
import { GetFormControlPipe } from '@tsp-pipes';
import { DateTime } from 'luxon';
import {
  all,
  equals,
  find,
  isNil,
  mergeRight,
  objOf,
  omit,
  pathOr,
  pipe,
  prop,
  propEq,
  propOr,
  reject,
  values,
} from 'ramda';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AddNewSourceComponent } from '../add-new-source/add-new-source.component';

@Component({
  imports: [
    AddNewSourceComponent,
    AsyncPipe,
    AutocompleteSelectComponent,
    CdkScrollable,
    CdkTextareaAutosize,
    CustomFieldsGroupComponent,
    GetFormControlPipe,
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatError,
    MatFormField,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatPrefix,
    MatSelect,
    MatSuffix,
    MatTooltip,
    NgClass,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    TranslatePipe,
    TranslateDirective,
  ],
  providers: [
    { provide: DateTimeAdapter, useClass: NativeDateTimeAdapter },
    { provide: OWL_DATE_TIME_FORMATS_PROVIDER, useValue: OWL_DATE_TIME_FORMATS },
  ],
  selector: 'short-list-edit-dialog',
  templateUrl: './short-list-edit-dialog.component.html',
  styleUrls: ['./short-list-edit-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ShortListEditDialogComponent implements OnInit {
  public shortListSources$: Observable<DictionaryItem[]>;
  public shortListSources: DictionaryItem[];
  public bidForm: FormGroup<BidForm>;
  public showAddSourceBlock = false;
  public showDatepicker: boolean;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly dialogRef: MatDialogRef<ShortListEditDialogComponent>,
    private readonly store$: Store<State>,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      hasAccessToSalary: boolean;
      hasAccessToStatusCustomFields: boolean;
      statusesCustomFields: DirectoryFieldType[];
      shortList: ShortList;
      statuses: DictionaryShortList[];
      rejectReasons: DictionaryItem[];
    },
  ) {}

  public ngOnInit(): void {
    this.shortListSources$ = this.store$.pipe(
      select(getShortListSources),
      tap((sources) => (this.shortListSources = sources)),
    );

    this.initForm();
  }

  public get interviewStartTime(): Date {
    const currentDateTime = this.bidForm.get('interviewAt').value;
    return currentDateTime
      ? new Date(currentDateTime)
      : DateTime.local().startOf('hour').toJSDate();
  }
  public checkStatusType() {
    this.showDatepicker = this.isStatusDateType() || !!this.bidForm.get('interviewAt').value;

    if (this.showDatepicker) {
      this.bidForm.get('interviewAt').enable();
    } else {
      this.bidForm.patchValue({
        interviewAt: null,
      });
      this.bidForm.get('interviewAt').disable();
    }

    if (this.selectedStatusCode === 'not_accepted') {
      this.bidForm.get('reasonRejectId').enable();
    } else {
      this.bidForm.get('reasonRejectId').disable();
    }
  }
  public closePopup() {
    this.dialogRef.close();
  }
  public controlHasErrorType(controlName: string, errorName: string): boolean {
    const control = this.bidForm.get(controlName);

    return control.valid || ((control.errors ? control.errors[errorName] : false) as boolean);
  }
  public saveBid() {
    if (this.bidForm.invalid) {
      return;
    }

    const sourceName = pipe(
      find(propEq(this.bidForm.value.source_id, 'id')) as UnaryOperator<
        DictionaryItem[],
        DictionaryItem
      >,
      prop('name'),
    )(this.shortListSources);
    const form = pipe(
      propOr([], 'fieldValues'),
      reject(pipe(omit(['type', 'id']), values, all(isNil))) as UnaryOperator<
        DirectoryCustomFieldResponse[],
        DirectoryCustomFieldResponse[]
      >,
      objOf('fieldValues'),
      mergeRight(this.bidForm.value),
    )(this.bidForm.value);

    const formValue = { ...form, sourceName, status: this.selectedStatus };

    this.dialogRef.close(formValue);
  }
  public clearInput(e: MouseEvent, controlName: string) {
    e.stopPropagation();
    this.bidForm.get(controlName).setValue(null);
  }

  private isStatusDateType(): boolean {
    return pipe(
      find(propEq(this.selectedStatusId, 'id')) as UnaryOperator<
        DictionaryShortList[],
        DictionaryShortList
      >,
      prop('code'),
      equals('interview_planned'),
    )(this.data.statuses);
  }
  private initForm() {
    const cvSentDate = this.data.shortList.cvSentDate
      ? new Date(this.data.shortList.cvSentDate)
      : null;
    const hiredAtDate = this.data.shortList.hiredAt ? new Date(this.data.shortList.hiredAt) : null;
    const interviewDate = this.data.shortList.interviewAt
      ? new Date(this.data.shortList.interviewAt)
      : null;
    const rejectedDate = this.data.shortList.rejectedDate
      ? new Date(this.data.shortList.rejectedDate)
      : null;

    this.bidForm = this.formBuilder.group<BidForm>({
      comment: new FormControl(this.data.shortList.comment),
      cvSentDate: new FormControl(cvSentDate),
      fieldValues: new FormControl(this.data.shortList.fieldValues || []),
      hiredAt: new FormControl(hiredAtDate),
      reasonRejectId: new FormControl(
        { value: this.data.shortList.rejectReason?.id, disabled: true },
        [Validators.required],
      ),
      rejectedDate: new FormControl(rejectedDate),
      source_id: new FormControl(pathOr(null, ['shortList', 'source', 'id'], this.data)),
      status: new FormControl(this.data.shortList.status.id, [Validators.required]),
      interviewAt: new FormControl({ value: interviewDate, disabled: true }),
      salary: new FormControl(this.data.shortList.salary, [numberValidator]),
    });

    this.checkStatusType();
    this.setValidators();
  }

  public get selectedStatusCode(): DictionaryShortListCodes | null {
    return prop('code', this.selectedStatus);
  }
  private get selectedStatusId(): number {
    return this.bidForm.get('status').value;
  }
  private get selectedStatus(): DictionaryShortList | null {
    return find(
      propEq(this.selectedStatusId, 'id'),
      this.data.statuses,
    ) as DictionaryShortList | null;
  }
  private setValidators() {
    const interviewAt = this.bidForm.get('interviewAt');
    if (this.isStatusDateType()) {
      interviewAt.setValidators(Validators.required);
    } else {
      interviewAt.clearValidators();
    }
    interviewAt.updateValueAndValidity();
  }
}
