import { CdkScrollable } from '@angular/cdk/scrolling';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AsyncPipe, DOCUMENT, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatSelect } from '@angular/material/select';
import { MatTabLink, MatTabNav, MatTabNavPanel } from '@angular/material/tabs';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { AddNewEntitySystemActions } from '@app-core/add-new-entity-popup/actions/new-entity.system.actions';
import { AddNewEntityUserActions } from '@app-core/add-new-entity-popup/actions/new-entity.user.actions';
import {
  isLoading as DataLoading,
  getCvParsingTimer,
  getDataFromFile,
  getDeveloperInfo,
  getMatchedProfileWithContacts,
  getMatchedProfileWithLinks,
  getProfileContacts,
} from '@app-core/add-new-entity-popup/reducers/new-entity.reducer';
import { CustomFieldsGroupComponent } from '@app-core/custom-fields/custom-fields-group/custom-fields-group.component';
import { RootSystemActions } from '@app-core/root/actions/root.system.actions';
import {
  getPreDefinedNewEntityData,
  getUserLocalization,
  isUserOptionAllowed,
  portalSupportUrl,
} from '@app-core/root/reducer/root.reducer';
import { ALLOWED_CV_FILE_TYPES } from '@app-shared/enums/constants';
import { isNotNullOrUndefined } from '@app-shared/functions/utilities/utilities';
import { OWL_DATE_TIME_FORMATS } from '@app-shared/helpers';
import {
  AllDictionaries,
  AnalyzedAiCvData,
  Contact,
  ContactLink,
  ContactsType,
  DictionaryItem,
  DictionaryShortList,
  DictionaryShortListSource,
  DirectoryFieldType,
  EmailContactForm,
  Entity,
  FullProfile,
  Locales,
  NewEntityPopupMode,
  NewProfileContactForm,
  NewProfileForm,
  PhoneContactForm,
  Profile,
  ProfileContactEntry,
  ProfileContacts,
  ProfileRequest,
  SkypeContactForm,
  SuggestedSkill,
  TalentFormTabs,
  TelegramContactForm,
  UnaryOperator,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import {
  getCustomFields,
  getDictionaryLoadingState,
  getProfilesDetailsDictionaries,
  getShortListSources,
  getShortListStatuses,
} from '@app-shared/reducers/dictionary/dictionary.reducer';
import { getCurrentPath } from '@app-shared/reducers/router/router.reducer';
import { getVacanciesList } from '@app-shared/reducers/vacancy/vacancy.reducer';
import { emailMatchValidator, lettersValidator, phoneValidator } from '@app-shared/validators';
import {
  DateTimeAdapter,
  NativeDateTimeAdapter,
  OWL_DATE_TIME_FORMATS as OWL_DATE_TIME_FORMATS_PROVIDER,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { Store, select } from '@ngrx/store';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { AutocompleteSelectComponent } from '@tsp-components/autocomplete-select';
import { ConfirmationDialogComponent } from '@tsp-components/confirmation-dialog';
import { FilePreviewComponent } from '@tsp-components/file-preview';
import { SpinnerComponent } from '@tsp-components/spinner';
import { TagInputComponent } from '@tsp-components/tag-input';
import { LimitInputDirective } from '@tsp-directives';
import { GetFormControlPipe } from '@tsp-pipes';
import { DateTime } from 'luxon';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import {
  all,
  always,
  anyPass,
  dropLast,
  equals,
  find,
  findIndex,
  gte,
  has,
  head,
  ifElse,
  includes,
  intersection,
  isEmpty,
  isNil,
  lensProp,
  mapObjIndexed,
  mergeAll,
  mergeRight,
  none,
  not,
  objOf,
  omit,
  over,
  path,
  pick,
  pipe,
  pluck,
  prop,
  propEq,
  propOr,
  propSatisfies,
  filter as ramdaFilter,
  map as ramdaMap,
  of as ramdaOf,
  reject,
  replace,
  trim,
  uniq,
  unless,
  values,
  when,
  without,
} from 'ramda';
import { Observable, Subject, combineLatest, interval, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { GoogleAutocompleteComponent } from '../google-autocomplete/google-autocomplete.component';
import { SuggestedParamsComponent } from '../suggested-params/suggested-params.component';

interface PreDefinedData {
  profile: Profile;
  contacts: ProfileContacts;
  vacancyId: number | null;
}

@Component({
  imports: [
    AsyncPipe,
    AutocompleteSelectComponent,
    CdkScrollable,
    CdkTextareaAutosize,
    CustomFieldsGroupComponent,
    DropzoneModule,
    FilePreviewComponent,
    GetFormControlPipe,
    GoogleAutocompleteComponent,
    LimitInputDirective,
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatError,
    MatFormField,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatProgressBar,
    MatSelect,
    MatSuffix,
    MatTabLink,
    MatTabNav,
    MatTabNavPanel,
    MatTooltip,
    NgClass,
    NgxIntlTelInputModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    RouterLink,
    SpinnerComponent,
    SuggestedParamsComponent,
    TagInputComponent,
    TranslatePipe,
    TranslateDirective,
  ],
  providers: [
    { provide: DateTimeAdapter, useClass: NativeDateTimeAdapter },
    { provide: OWL_DATE_TIME_FORMATS_PROVIDER, useValue: OWL_DATE_TIME_FORMATS },
  ],
  selector: 'app-new-talent-dialog',
  templateUrl: './new-talent-dialog.component.html',
  styleUrls: ['./new-talent-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NewTalentDialogComponent implements OnInit, OnDestroy {
  public documentParsingTimer$: Observable<number>;
  public isContactsLoading$?: Observable<boolean>;
  public isLoading$?: Observable<boolean>;
  public isSimpleForm$: Observable<boolean>;
  public shortListSources$?: Observable<DictionaryShortListSource[]>;
  public supportUrl$?: Observable<string>;
  public userLocale$: Observable<Locales>;

  public aiSuggestionsHints: Array<[string, number]> = null;
  public cvContent?: string;
  public cvMimetype?: string;
  public dictionaries?: Partial<AllDictionaries>;
  public expectedCvAnalyzeTime: number;
  public fileUrlForPreview?: string;
  public hasAccessToCustomFields: boolean;
  public hasAccessToSocialLinks: boolean;
  public hasAccessToSecondName: boolean;
  public hasAccessToSalary: boolean;
  public renderAdditionalInformation?: boolean;
  public renderProfessionalInformation?: boolean;
  public fileName: string | null = null;
  public renderSocialLinks?: boolean;
  public newTalentForm?: FormGroup<NewProfileForm>;
  public matchedProfileWithContacts?: { contact: string; profile: Profile }[];
  public matchedProfileWithLinks?: { link: string; profile: Profile }[];
  public profileVacanciesStatuses?: DictionaryShortList[] = [];
  public selectedTemplate = TalentFormTabs.INITIAL;
  public showFullForm = true;
  public showSkillsSuggestion = true;
  public showSoftSkillsSuggestion = true;
  public showSpecialitiesSuggestion = true;
  public suggestedSpecialities = [];
  public TalentFormTabs = TalentFormTabs;
  public templates = [
    {
      index: TalentFormTabs.INITIAL,
      label: 'add-new-entity.new-talent.title.initial-info',
    },
    {
      index: TalentFormTabs.DETAILS,
      label: 'add-new-entity.new-talent.title.prof-info',
    },
    {
      index: TalentFormTabs.SOCIALS,
      label: 'add-new-entity.new-talent.title.social-links',
    },
    {
      index: TalentFormTabs.ADDITIONAL,
      label: 'add-new-entity.new-talent.title.additional-info',
    },
  ];
  public fullProfile?: FullProfile;
  public dropzoneConfig = {
    url: '/api/cv/parse',
    autoProcessQueue: false,
    acceptedFiles: ALLOWED_CV_FILE_TYPES,
  };
  public profileCustomFields: DirectoryFieldType[];
  public uploadedFile?: File;
  public regExp = new RegExp(/\s/g);
  public phoneRegExp = new RegExp(/\D/g);
  public vacanciesList?: DictionaryItem[];

  private formPreviousValue: Record<string, unknown> = {};
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      mode: NewEntityPopupMode;
      parentParams?: string;
      template?: TalentFormTabs;
    },
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly store$: Store<State>,
    private readonly dialog: MatDialog,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  public ngOnInit(): void {
    this.selectedTemplate = this.data.template || TalentFormTabs.INITIAL;

    setTimeout(() => this.scrollToContent(this.selectedTemplate), 1000);

    this.store$.dispatch(AddNewEntitySystemActions.OpenTalentDialogOnInitAction());

    combineLatest([
      this.store$.pipe(select(isUserOptionAllowed('showProfileCustomFields'))),
      this.store$.pipe(select(isUserOptionAllowed('showProfileSecondName'))),
      this.store$.pipe(select(isUserOptionAllowed('showProfileSocialLinks'))),
      this.store$.pipe(select(isUserOptionAllowed('showSalary'))),
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([customFields, secondName, socialLinks, showSalary]) => {
        this.hasAccessToCustomFields = customFields;
        this.hasAccessToSecondName = secondName;
        this.hasAccessToSocialLinks = socialLinks;
        this.hasAccessToSalary = showSalary;
      });

    this.isLoading$ = combineLatest([
      this.store$.pipe(select(getDictionaryLoadingState)),
      this.store$.pipe(select(DataLoading)),
    ]).pipe(map(([dictionaryLoading, dataLoading]) => dictionaryLoading || dataLoading));

    this.store$
      .pipe(select(getVacanciesList), takeUntil(this.ngUnsubscribe))
      .subscribe((list) => (this.vacanciesList = list));

    this.shortListSources$ = this.store$.pipe(
      select(getShortListSources),
      takeUntil(this.ngUnsubscribe),
    );

    this.store$
      .pipe(
        select(getCustomFields('profileCustomFields')),
        filter(isNotNullOrUndefined),
        map((customFields) => ramdaFilter((field) => !isNil(field.showInPanel), customFields)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((customFields) => (this.profileCustomFields = customFields));

    this.store$
      .pipe(
        select(getCurrentPath),
        map((currentPath) => includes('simpleTalent', currentPath)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((state) => (this.showFullForm = !state));

    this.store$
      .pipe(select(getProfilesDetailsDictionaries))
      .pipe(
        filter((dictionaries) => !isNil(dictionaries)),
        distinctUntilChanged((prevData, newData) => equals(prevData, newData)),
        tap((dictionaries) => {
          this.dictionaries = dictionaries;
        }),
        map(() => true),
        distinctUntilChanged(),
        switchMap(() => this.store$.pipe(select(getPreDefinedNewEntityData))),
        tap((preDefinedData) => {
          this.initGroup();

          this.createContactsGroup(null);
          this.createSocialLinksGroup([]);

          if (preDefinedData) {
            this.setPreDefinedData(preDefinedData);
          }

          this.formPreviousValue = this.newTalentForm.value;
        }),
        filter(() => !!this.data.parentParams),
        tap(() => {
          this.store$.dispatch(
            AddNewEntitySystemActions.RequestProfileDetailsOnInitAction({
              id: this.data.parentParams,
            }),
          );
        }),
        switchMap(() =>
          combineLatest([
            this.store$.pipe(select(getDeveloperInfo)),
            this.store$.pipe(select(getProfileContacts)),
          ]),
        ),
        filter((data: [FullProfile, ProfileContacts]) => none(isNil)(data)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([profileDetails, contacts]: [FullProfile, ProfileContacts]) => {
        this.fullProfile = profileDetails;
        this.updateFormWithData(profileDetails);
        this.createContactsGroup(contacts.mainContacts);
        this.createSocialLinksGroup(contacts.socialLinks);
        this.formPreviousValue = this.newTalentForm.value;
      });

    this.store$
      .pipe(select(getDataFromFile), filter(isNotNullOrUndefined), takeUntil(this.ngUnsubscribe))
      .subscribe((data: AnalyzedAiCvData) => {
        this.cvContent = data.textContent;
        this.cvMimetype = data.cvMimetype;
        this.fileUrlForPreview = data.urlToCV;
        this.aiSuggestionsHints = data.allowFullResponse ? null : data.suggestions;
        this.setOpenAiSuggestions(data);
        this.newTalentForm.markAllAsTouched();
      });
    this.store$
      .pipe(select(getMatchedProfileWithContacts), takeUntil(this.ngUnsubscribe))
      .subscribe((profileWithContacts: { contact: string; profile: Profile }[]) => {
        this.matchedProfileWithContacts = profileWithContacts;
        this.changeDetector.detectChanges();
      });
    this.store$
      .pipe(select(getMatchedProfileWithLinks), takeUntil(this.ngUnsubscribe))
      .subscribe((profileWithLinks: { link: string; profile: Profile }[]) => {
        this.matchedProfileWithLinks = profileWithLinks;
        this.changeDetector.detectChanges();
      });
    this.userLocale$ = this.store$.pipe(select(getUserLocalization), shareReplay());

    this.store$
      .pipe(select(getShortListStatuses), takeUntil(this.ngUnsubscribe))
      .subscribe((statuses) => (this.profileVacanciesStatuses = statuses));

    this.documentParsingTimer$ = this.store$.pipe(
      select(getCvParsingTimer),
      filter(isNotNullOrUndefined),
      tap((timer) => (this.expectedCvAnalyzeTime = timer)),
      map((time) => (time * 1000) / 100),
      switchMap((timer) => (timer ? interval(timer).pipe(take(100)) : of(0))),
    );

    this.supportUrl$ = this.store$.pipe(select(portalSupportUrl), shareReplay());
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public addContact(contact: string, control: string): void {
    const validator = this.createContactValidator(control);
    const newGroup = this.formBuilder.group({ [control]: [null, validator], main: [false] });
    this.contactsForm(contact).push(newGroup);
    this.watchContactsChange(newGroup, contact, control);
  }
  public addSocialLink(): void {
    const control = new FormControl('', [Validators.required]);
    this.socialLinksForm.push(control);
    this.watchContactsChange(control, 'socialLinks');
  }
  public checkUserExistenceByContacts(controlName: string, name: string, index: number): void {
    const control = this.newTalentForm.get([controlName, index]);
    if (!control?.valid) {
      return;
    }
    this.store$.dispatch(
      AddNewEntityUserActions.CheckProfileExistenceAction({
        devId: this.fullProfile?.id,
        source: 'contact',
        keyword: prop(name, control.value),
      }),
    );
  }
  public checkUserExistenceByLinks(controlName: string, index: number) {
    this.store$.dispatch(
      AddNewEntityUserActions.CheckProfileExistenceAction({
        devId: this.fullProfile?.id,
        source: 'link',
        keyword: this.newTalentForm.get([controlName, index]).value,
      }),
    );
  }
  public contactsFormControls(currentContact: string): FormControl[] {
    return this.contactsForm(currentContact)
      ? (this.contactsForm(currentContact).controls as FormControl[])
      : [];
  }
  public contactControlHasErrorType(
    controlName: string,
    controlPath: [string, number],
    errorName: string,
  ): boolean {
    const group = this.newTalentForm.get(controlPath) as unknown as FormArray<FormControl<string>>;

    const control = path(['controls', controlName])(group) as FormControl;

    if (!control) {
      return false;
    }

    return control.touched && (control.errors ? (control.errors[errorName] as boolean) : false);
  }
  public controlHasErrorType(controlName: string | [string, number], errorName: string): boolean {
    const control = this.newTalentForm.get(controlName);

    if (!control) {
      return false;
    }

    return control.touched && (control.errors ? (control.errors[errorName] as boolean) : false);
  }
  public deleteFile() {
    this.fileName = null;
    this.uploadedFile = null;
    this.fileUrlForPreview = null;
  }
  public contactsForm(contacts: string): FormArray {
    return this.newTalentForm.get(contacts) as FormArray;
  }
  public getSkills(type: 'skills' | 'softSkills'): { skills: number[] } {
    return {
      skills: this.newTalentForm.get(type).value || [],
    };
  }
  public isModified(controlName: string): boolean {
    const control = this.newTalentForm.get(controlName);

    if (!control) {
      return false;
    }

    const valueChanged = !equals(control.value, this.formPreviousValue[controlName]);

    return control.touched && valueChanged;
  }
  public isModifiedInGroup(groupName: string, index: number): boolean {
    const group = this.newTalentForm.get(groupName) as FormArray<FormControl<string>>;

    if (!group) {
      return false;
    }

    const prevValue = this.formPreviousValue[groupName][index];
    const currentValue = group.value[index];

    const valueChanged = !equals(currentValue, prevValue);

    return group.touched && group.controls[index].touched && valueChanged;
  }
  public isPristine(): boolean {
    return propOr(true, 'pristine', this.newTalentForm);
  }
  public isSectionInvalid(section: TalentFormTabs): boolean {
    const controls = this.newTalentForm?.controls;
    if (!controls) {
      return false;
    }
    const controlsWithError: string[] = [];

    for (const c in controls) {
      const control: FormControl = controls[c];

      if (control.status === 'INVALID') {
        controlsWithError.push(c);
      }
    }

    let sectionKeys: string[] = [];

    switch (section) {
      case TalentFormTabs.INITIAL:
        sectionKeys = [
          'vacancy',
          'firstName',
          'middleName',
          'lastName',
          'emails',
          'skypes',
          'phones',
          'telegrams',
          'city',
          'birthday',
        ];
        break;
      case TalentFormTabs.DETAILS:
        sectionKeys = [
          'level',
          'english',
          'mainSkill',
          'speciality',
          'skills',
          'softSkills',
          'currency',
          'rate',
          'rateCard',
          'salaryComment',
          'profileSummary',
          'insideInformation',
        ];
        break;
      case TalentFormTabs.ADDITIONAL:
        sectionKeys = ['profileCustomFields'];
        break;
      case TalentFormTabs.SOCIALS:
        sectionKeys = ['socialLinks'];
        break;
      default:
        sectionKeys = [];
    }

    return pipe(
      intersection(sectionKeys) as UnaryOperator<(string | number)[], string[]>,
      isEmpty,
      not,
    )(controlsWithError);
  }
  public get isFormInvalid(): boolean {
    return !this.isPristine() && this.newTalentForm?.invalid;
  }
  public get popupTitle(): string {
    return this.data.mode === 'EDIT'
      ? 'add-new-entity.new-talent.title.update'
      : 'add-new-entity.new-talent.title.create';
  }
  public get selectedCity(): number {
    return this.newTalentForm.get('city').value;
  }
  public get showDropzone(): boolean {
    return this.data.mode !== 'EDIT' && this.showFullForm && !this.fileUrlForPreview;
  }
  public get socialLinksForm(): FormArray {
    return this.newTalentForm.get('socialLinks') as FormArray;
  }
  public get socialLinksFormControls(): AbstractControl[] {
    return this.socialLinksForm.controls;
  }
  public get suggestedSkills(): number[] {
    return pipe(
      propOr([], 'suggestedSkills') as UnaryOperator<FullProfile, SuggestedSkill[]>,
      reject(propEq(true, 'soft')),
      pluck('id') as UnaryOperator<SuggestedSkill[], number[]>,
    )(this.fullProfile);
  }
  public get suggestedSoftSkills(): number[] {
    return pipe(
      propOr([], 'suggestedSkills') as UnaryOperator<FullProfile, SuggestedSkill[]>,
      reject(propEq(false, 'soft')),
      pluck('id') as UnaryOperator<SuggestedSkill[], number[]>,
    )(this.fullProfile);
  }
  public selectSuggestedSkill(id: number) {
    this.store$.dispatch(AddNewEntityUserActions.SelectSuggestedSkillAction({ id }));
  }
  public selectSuggestedSpeciality(id: number) {
    this.newTalentForm.get('speciality').patchValue(id);
    this.suggestedSpecialities = without([id], this.suggestedSpecialities);
  }
  public scrollToContent(template: TalentFormTabs) {
    const content = this.document.querySelector(`#anchor-${template}`);
    if (content) {
      content.scrollIntoView({ behavior: 'smooth' });
    }
  }
  public showControlOnTop(controlName: string): boolean {
    if (!this.fileUrlForPreview) {
      return false;
    }

    const value = this.newTalentForm.get(controlName)?.value;

    if (['emails', 'phones', 'skypes', 'telegrams'].includes(controlName)) {
      const valueName = controlName.slice(0, -1) as 'email' | 'phone' | 'skype' | 'telegram';

      return pipe(
        pluck(valueName),
        reject(anyPass([isNil, isEmpty])),
        isEmpty,
        not,
      )(value as { [key in 'email' | 'phone' | 'skype' | 'telegram']: string }[]);
    }

    return !isNil(value) && !isEmpty(value);
  }
  public changeTemplate(template: TalentFormTabs) {
    this.store$.dispatch(
      AddNewEntityUserActions.ChangeEditTalentDialogTabAction({
        template,
        devId: this.data.parentParams,
        isEditing: this.data.mode === 'EDIT',
      }),
    );
    this.selectedTemplate = template;
    this.scrollToContent(template);
  }
  public isTabInvisible(index: TalentFormTabs): boolean {
    return index === TalentFormTabs.ADDITIONAL && !this.profileCustomFields?.length;
  }
  public getExistedProfile(
    type: 'link' | 'contact',
    controlName: string,
    index: number,
    controlPath: string[],
  ): { contact?: string; link?: string; profile: Profile } {
    const data = type === 'link' ? this.matchedProfileWithLinks : this.matchedProfileWithContacts;
    const controlValue: string = path(controlPath, this.newTalentForm.get([controlName, index]));

    if (!data?.length) {
      return null;
    }

    return type === 'contact'
      ? data.find((p: { contact: string; profile: Profile }) => p.contact === controlValue)
      : data.find((p: { link: string; profile: Profile }) => p.link === controlValue);
  }
  public get normalizedFormData(): Partial<ProfileRequest> {
    const formData = this.newTalentForm.value as Record<string, unknown>;

    return pipe(
      pick(['emails', 'skypes', 'phones', 'telegrams']) as UnaryOperator<
        Record<string, unknown>,
        Record<string, string[]>
      >,
      mapObjIndexed((contacts, contactType: string) =>
        reject(propSatisfies(anyPass([isNil, isEmpty]), dropLast(1, contactType)), contacts),
      ),
      mergeRight(formData),
      over(lensProp('firstName'), unless(isNil, trim)),
      over(lensProp('lastName'), unless(isNil, trim)),
      omit(['vacancy']),
    )(formData);
  }
  public onFileAdded(file: File) {
    this.fileName = file.name;
    const safeFileName = encodeURIComponent(file.name);
    this.uploadedFile = new File([file], safeFileName, { type: file.type });
    this.store$.dispatch(
      AddNewEntityUserActions.UploadCvFromPopupAction({ file: this.uploadedFile }),
    );
  }
  public onRenderAdditionalInformation() {
    this.renderAdditionalInformation = !this.renderAdditionalInformation;
  }
  public onRenderProfessionalInformation() {
    this.renderProfessionalInformation = !this.renderProfessionalInformation;
  }
  public onRenderSocialLinks() {
    this.renderSocialLinks = !this.renderSocialLinks;
  }
  public openClosePopup() {
    if (this.isPristine()) {
      this.dialog.closeAll();
    } else {
      this.store$.dispatch(RootSystemActions.OpenConfirmationDialogForClosePopupAction());
    }
  }
  public openDeletePopup() {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          text: `add-new-entity.new-talent.confirmation-dialog.delete`,
        },
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.store$.dispatch(
            AddNewEntityUserActions.DeleteProfileFromPopupAction({
              id: this.fullProfile.id,
            }),
          );
        }
      });
  }
  public removeBirthday(): void {
    this.newTalentForm.get('birthday').patchValue(null);
  }
  public removeContact(contact: string, index: number) {
    this.contactsForm(contact).removeAt(index);
  }
  public removeSocialLink(index: number) {
    this.socialLinksForm.removeAt(index);
  }
  public submit(navigateToCreated: boolean, isForced = false) {
    if (this.newTalentForm.invalid) {
      this.checkFormErrors();
      return;
    }
    const areThereMatchedProfiles = !all(anyPass([isNil, isEmpty]))([
      this.matchedProfileWithContacts,
      this.matchedProfileWithLinks,
    ]);
    if (areThereMatchedProfiles && !isForced) {
      this.checkIsMatchedProfileExist(navigateToCreated);
      return;
    }
    const longListStatus = pipe(
      find(propEq('long_list', 'code')) as UnaryOperator<
        DictionaryShortList[],
        DictionaryShortList
      >,
      prop('id'),
    )(this.profileVacanciesStatuses);

    const formData = this.normalizedFormData;

    if (formData.birthday && !DateTime.fromISO(formData.birthday).isValid) {
      const birthdayValue = DateTime.fromJSDate(formData.birthday as unknown as Date)
        .plus({ hours: 12 })
        .toISO({ suppressMilliseconds: true });

      formData.birthday = birthdayValue;
    }
    if (this.data.mode === 'EDIT') {
      const payload: Partial<ProfileRequest> = { ...formData, id: this.fullProfile.id };
      this.store$.dispatch(AddNewEntityUserActions.UpdateTalentAction({ profile: payload }));
    } else {
      const vacancy = find(propEq(this.newTalentForm.value.vacancy, 'id'))(
        this.vacanciesList,
      ) as DictionaryItem;
      this.store$.dispatch(
        AddNewEntityUserActions.CreateNewTalentAction({
          talent: formData,
          cvMimetype: this.cvMimetype || null,
          textContent: this.cvContent || null,
          urlToCv: this.fileUrlForPreview || null,
          vacancy,
          status: longListStatus,
          navigateToCreated,
        }),
      );
    }
  }
  public swapName() {
    const firstName = this.newTalentForm.get('firstName').value;
    const lastName = this.newTalentForm.get('lastName').value;
    this.newTalentForm.patchValue({ firstName: lastName, lastName: firstName });
  }
  public trackById(_index: number, element: DictionaryItem) {
    return element.id;
  }
  public trackControls(index: number, control: AbstractControl): string {
    return control.value as string;
  }
  public updateCity({ city, country }: { city: number | null; country: number | null }) {
    this.newTalentForm.patchValue({
      city,
      country,
    });
  }
  public updateSkills(
    newSkills: { skills?: number[]; softSkills?: number[] },
    controlName: 'skills' | 'softSkills',
  ) {
    this.newTalentForm.get(controlName).patchValue(newSkills[controlName]);
  }

  private addNewSocialLinks(links: string[]) {
    const socialForm = this.newTalentForm.get('socialLinks') as FormArray;
    const linkControls = ramdaMap((link) => new FormControl(link), links);
    linkControls.forEach((control) => {
      socialForm.push(control);
      this.watchContactsChange(control, 'socialLinks');
    });
  }
  private calculateControlValueWithAiSuggestion(
    controlName: string,
    value: string | string[] | DictionaryItem[],
  ): null | string | number | number[] | Date | string[] | DictionaryItem[] | Contact[] {
    if (!value) {
      return null;
    }

    if (
      ['currency', 'firstName', 'middleName', 'lastName', 'profileSummary', 'rateCard'].includes(
        controlName,
      )
    ) {
      return value as string;
    }

    if (controlName === 'birthday') {
      const date = DateTime.fromFormat(value as string, 'yyyy-MM-dd');

      return date.isValid ? date.set({ hour: 12 }).toUTC().toISO() : null;
    }

    if (['emails', 'phones', 'skypes', 'telegrams'].includes(controlName)) {
      return pipe(
        uniq,
        ramdaMap(pipe(objOf('value'), mergeRight({ main: false, unsubscribed: null }))),
      )(value as string[]);
    }

    if (controlName === 'socialLinks') {
      return value as string[];
    }

    if (['city', 'level', 'english', 'mainSkills', 'speciality'].includes(controlName)) {
      return pipe(
        ramdaFilter(propSatisfies(gte(0.25), 'score')) as UnaryOperator<
          DictionaryItem[],
          DictionaryItem[]
        >,
        head as UnaryOperator<DictionaryItem[], DictionaryItem>,
        prop('id') as UnaryOperator<DictionaryItem, number>,
      )(value as DictionaryItem[]);
    }

    if (['skills', 'softSkills'].includes(controlName)) {
      return pipe(
        ramdaFilter(propSatisfies(gte(0.25), 'score')) as UnaryOperator<
          DictionaryItem[],
          DictionaryItem[]
        >,
        pluck('id'),
      )(value as DictionaryItem[]);
    }

    return null;
  }
  private checkFormErrors() {
    const controls = this.newTalentForm.controls;
    for (const control in controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (controls.hasOwnProperty(control)) {
        const currentControl: FormControl = controls[control];
        if (!isNil(currentControl.validator) && currentControl.untouched) {
          currentControl.markAsTouched();
        }
      }
    }
  }
  private createContactValidator(contactName: string) {
    switch (contactName) {
      case 'email':
        return [emailMatchValidator];
      case 'phone':
        return [phoneValidator];
      case 'skype':
      case 'telegram':
      default:
        return [];
    }
  }
  private createContactsGroup(profileContacts: { [key: string]: ProfileContactEntry[] }) {
    const contactGroups: ContactsType[] = ['emails', 'skypes', 'phones', 'telegrams'];

    contactGroups.forEach((groupName: ContactsType) => {
      const controlName = dropLast(1, groupName);
      const validator = this.createContactValidator(controlName);

      const contactControls = pipe(
        prop(groupName),
        ifElse(
          anyPass([isNil, isEmpty]),
          pipe(
            () =>
              this.formBuilder.group({
                [controlName]: [null, validator],
                main: false,
              }),
            ramdaOf(Array),
          ),
          ramdaMap((contact: ProfileContactEntry) => {
            const contactValue =
              controlName === 'phone' ? replace(/\D/g, '', contact.value) : contact.value;

            return this.formBuilder.group({
              [controlName]: [contactValue, validator],
              main: contact.main,
            });
          }),
        ),
      )(profileContacts) as unknown as FormGroup<NewProfileContactForm>[];

      const socialControls = new FormArray(contactControls) as
        | FormArray<FormGroup<EmailContactForm>>
        | FormArray<FormGroup<SkypeContactForm>>
        | FormArray<FormGroup<PhoneContactForm>>
        | FormArray<FormGroup<TelegramContactForm>>;

      this.newTalentForm.setControl(groupName, socialControls);
      contactControls.forEach((contactControl) =>
        this.watchContactsChange(contactControl, groupName, controlName),
      );
    });
  }
  private createSocialLinksGroup(profileContacts: Array<Entity & ContactLink>) {
    const socialForm = this.newTalentForm.get('socialLinks') as FormArray;
    while (socialForm.length !== 0) {
      socialForm.removeAt(0);
    }
    const linkControls = ramdaMap((link) => new FormControl(link.url), profileContacts);
    linkControls.forEach((control) => {
      socialForm.push(control);
      this.watchContactsChange(control, 'socialLinks');
    });
  }
  private initGroup() {
    this.newTalentForm = this.formBuilder.group<NewProfileForm>({
      birthday: new FormControl(null),
      city: new FormControl(null),
      country: new FormControl(null),
      currency: new FormControl(null),
      fieldValues: new FormControl([]),
      emails: new FormArray([]),
      english: new FormControl(null),
      firstName: new FormControl(null, [Validators.required, lettersValidator]),
      insideInformation: new FormControl(null),
      image: new FormControl(null),
      lastName: new FormControl(null, [lettersValidator]),
      level: new FormControl(null),
      mainSkill: new FormControl(null),
      middleName: new FormControl(null, [lettersValidator]),
      profileSummary: new FormControl(null),
      rate: new FormControl(null),
      rateCard: new FormControl(null),
      salaryComment: new FormControl(null),
      skills: new FormControl([]),
      softSkills: new FormControl([]),
      sourceId: new FormControl(null),
      skypes: new FormArray([]),
      speciality: new FormControl(null),
      socialLinks: new FormArray([]),
      telegrams: new FormArray([]),
      vacancy: new FormControl(null),
      phones: new FormArray([]),
    });
  }
  private setOpenAiSuggestions(data: AnalyzedAiCvData) {
    const controlMappingKeys = {
      birthDate: 'birthday',
      currency: 'currency',
      emails: 'emails',
      firstName: 'firstName',
      lastName: 'lastName',
      middleName: 'middleName',
      phones: 'phones',
      salary: 'rateCard',
      skypes: 'skypes',
      socialLinks: 'socialLinks',
      suggestedCities: 'city',
      suggestedEnglishLevels: 'english',
      suggestedHardSkills: 'skills',
      suggestedLevels: 'level',
      suggestedMainSkills: 'mainSkill',
      suggestedSoftSkills: 'softSkills',
      suggestedSpeciality: 'speciality',
      summary: 'profileSummary',
      telegrams: 'telegrams',
    };

    const suggestions = pipe(
      mapObjIndexed((rawValue, rawKey) => {
        const key: string = controlMappingKeys[rawKey];

        if (!key) {
          return null;
        }

        const value = this.calculateControlValueWithAiSuggestion(
          key,
          rawValue as string | string[] | DictionaryItem[],
        );

        return { [key]: value };
      }) as UnaryOperator<
        AnalyzedAiCvData,
        Record<keyof AnalyzedAiCvData, Record<string, string[]>>
      >,
      values as UnaryOperator<
        Record<keyof AnalyzedAiCvData, Record<string, string[]>>,
        Record<string, string[]>[]
      >,
      reject(anyPass([isNil, isEmpty])) as UnaryOperator<
        Record<string, string[]>[],
        Record<string, string[]>[]
      >,
      mergeAll as UnaryOperator<Record<string, string[]>[], Record<string, string[]>>,
      reject(anyPass([isNil, isEmpty])),
    )(data);

    this.newTalentForm.patchValue(suggestions);

    const contactSuggestions = pick(
      ['emails', 'phones', 'skypes', 'telegrams'],
      suggestions,
    ) as unknown as Record<string, ProfileContactEntry[]>;

    this.createContactsGroup(contactSuggestions);

    if (suggestions.socialLinks) {
      this.addNewSocialLinks(suggestions.socialLinks);
    }
  }
  private setPreDefinedData(data: PreDefinedData) {
    const clear = when(anyPass([isNil, isEmpty]), always(null));
    this.updateFormWithData(data.profile as FullProfile);
    this.createContactsGroup(data.contacts.mainContacts);
    this.createSocialLinksGroup(data.contacts.socialLinks);
    this.newTalentForm.patchValue({
      city: clear(data.profile.cityId),
      country: clear(data.profile.countryId),
      vacancy: data.vacancyId,
    });
  }
  private updateFormWithData(profileData: FullProfile) {
    this.newTalentForm.patchValue({
      birthday: profileData.birthday || null,
      city: profileData.city?.id || null,
      country: profileData.country?.id || null,
      currency: profileData.currency || null,
      fieldValues: profileData.fieldValues || [],
      english: profileData.englishLevel || null,
      firstName: profileData.firstName || null,
      insideInformation: profileData.insideInformation || null,
      image: profileData.image || null,
      lastName: profileData.lastName || null,
      level: profileData.professionalLevel || null,
      mainSkill: profileData.mainSkill?.id || null,
      middleName: profileData.middleName || null,
      profileSummary: profileData.summary || null,
      rate: profileData.rate || null,
      rateCard: profileData.rateCard || null,
      salaryComment: profileData.salaryComment || null,
      skills: profileData.skills?.map((skill) => skill.id) || [],
      softSkills: profileData.softSkills?.map((skill) => skill.id) || [],
      sourceId: profileData.source?.id || null,
      speciality: profileData.speciality || null,
    });
  }

  private watchContactsChange(
    control: FormControl | FormGroup,
    groupName: string,
    controlName?: string,
  ) {
    const controlValue = has(controlName, control.value)
      ? control.value[controlName]
      : control.value;
    const check = (prevValue?: string) => {
      const index = findIndex(
        equals(control),
        (this.newTalentForm.get(groupName) as FormArray).controls,
      );
      this.removeMatchedProfile(prevValue, groupName);
      if (groupName === 'socialLinks') {
        this.checkUserExistenceByLinks(groupName, index);
      } else {
        this.checkUserExistenceByContacts(groupName, controlName, index);
      }
    };
    if (controlValue) {
      check();
    }
    control.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged((prevData, newData) => equals(prevData, newData)),
        startWith(''),
        pairwise(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([prevValue]: string[]) => check(prevValue));
  }

  private removeMatchedProfile(value: string | { [key: string]: string }, groupName: string) {
    if (groupName === 'socialLinks') {
      this.store$.dispatch(
        AddNewEntitySystemActions.RemoveMatchedProfileByLinkAction({ link: value as string }),
      );
    } else {
      const controlName = dropLast(1, groupName);
      const contact = prop(controlName, value as { [key: string]: string });
      this.store$.dispatch(
        AddNewEntitySystemActions.RemoveMatchedProfileByContactAction({ contact }),
      );
    }
  }

  private checkIsMatchedProfileExist(navigateToCreated: boolean) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: { text: 'add-new-entity.new-talent.confirmation-dialog.duplicate' },
        width: '400px',
      })
      .afterClosed()
      .pipe(filter((result: boolean | null) => result))
      .subscribe(() => {
        this.submit(navigateToCreated, true);
      });
  }
}
